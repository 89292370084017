import Icon from "@ant-design/icons";

import { LIST_PERMISSION } from "_constants/Permission/permission";
import { order } from "layouts/Icon";
import { OrderDetail, OrderList } from "./lazy-import";

const ORDER = [
  {
    key: "/orders",
    title: "Quản lý đơn hàng",
    label: "Quản lý đơn hàng",
    icon: <Icon component={order} />,
    component: props => <OrderList {...props} />,
    action_key: LIST_PERMISSION.ORDER_VIEW,
    display: 1,
  },
  {
    key: "/orders/:id",
    title: "Chi tiết đơn hàng",
    label: "Chi tiết đơn hàng",
    component: props => <OrderDetail {...props} />,
    action_key: LIST_PERMISSION.ORDER_VIEW,
  },
];
export default ORDER;
