import Icon from "@ant-design/icons";

import { LIST_PERMISSION } from "_constants/Permission/permission";
import { guest } from "layouts/Icon";
import {
  GuestQuestionCreate,
  GuestQuestionDetail,
  GuestQuestionList,
  GuestSetQuestionCreate,
  GuestSetQuestionDetail,
  GuestSetQuestionList,
  GuestVideoCreate,
  GuestVideoDetail,
  GuestVideoList,
  ProductGuest,
} from "./lazy-import";

const GUEST = [
  {
    key: `/manager-guest`,
    label: "Khách vãng lai",
    title: "Khách vãng lai",
    icon: <Icon component={guest} />,
    display: 1,
    children: [
      {
        key: "/manager-guest/video",
        title: "Video giới thiệu",
        label: "Video giới thiệu",
        component: props => <GuestVideoList {...props} />,
        display: 1,
        action_key: LIST_PERMISSION.GUESTS_VIDEO_VIEW,
      },
      {
        key: "/manager-guest/video/:id",
        label: "Chi tiết trò chơi",
        title: "Chi tiết trò chơi",
        component: props => <GuestVideoDetail {...props} />,
        action_key: LIST_PERMISSION.GUESTS_VIDEO_VIEW,
      },
      {
        key: "/manager-guest/video/add",
        label: "Chi tiết trò chơi",
        title: "Chi tiết trò chơi",
        component: props => <GuestVideoCreate {...props} />,
        action_key: LIST_PERMISSION.GUESTS_VIDEO_ADD,
      },
      {
        key: "/manager-guest/guest",
        title: "Câu hỏi trắc nghiệm",
        label: "Câu hỏi trắc nghiệm",
        display: 1,
        children: [
          {
            key: "/manager-guest/guest/set-question",
            title: "Bộ câu hỏi",
            label: "Bộ câu hỏi",
            display: 1,
            component: props => <GuestSetQuestionList {...props} />,
            action_key: LIST_PERMISSION.GUESTS_SET_OF_QUESTION_VIEW,
          },
          {
            key: "/manager-guest/guest/set-question/:id",
            label: "Chi tiết câu hỏi",
            title: "Chi tiết câu hỏi",
            component: props => <GuestSetQuestionDetail {...props} />,
            action_key: LIST_PERMISSION.GUESTS_SET_OF_QUESTION_VIEW,
          },
          {
            key: "/manager-guest/guest/set-question/add",
            label: "Thêm câu hỏi",
            title: "Thêm câu hỏi",
            component: props => <GuestSetQuestionCreate {...props} />,
            action_key: LIST_PERMISSION.GUESTS_SET_OF_QUESTION_ADD,
          },
          {
            key: "/manager-guest/guest/question",
            title: "Câu hỏi",
            label: "Câu hỏi",
            display: 1,
            component: props => <GuestQuestionList {...props} />,
            action_key: LIST_PERMISSION.GUESTS_QUESTION_VIEW,
          },
          {
            key: "/manager-guest/guest/question/:id",
            label: "Chi tiết câu hỏi",
            title: "Chi tiết câu hỏi",
            component: props => <GuestQuestionDetail {...props} />,
            action_key: LIST_PERMISSION.GUESTS_QUESTION_VIEW,
          },
          {
            key: "/manager-guest/guest/question/add",
            label: "Thêm câu hỏi",
            title: "Thêm câu hỏi",
            component: props => <GuestQuestionCreate {...props} />,
            action_key: LIST_PERMISSION.GUESTS_QUESTION_ADD,
          },
        ],
      },
      {
        key: "/manager-guest/product",
        title: "Danh sách sản phẩm",
        label: "Danh sách sản phẩm",
        display: 1,
        component: props => <ProductGuest {...props} />,
        action_key: LIST_PERMISSION.GUESTS_PRODUCT_VIEW,
      },
    ],
  },
];
export default GUEST;
