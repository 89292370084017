import Icon from "@ant-design/icons";
import { voucher } from "layouts/Icon";
import { VoucherCreate, VoucherDetail, VoucherList } from "./lazy-import";
import { LIST_PERMISSION } from "_constants/Permission/permission";

const VOUCHER = [
  {
    key: `/vouchers`,
    label: "Voucher",
    title: "Voucher",
    icon: <Icon component={voucher} />,
    action_key: LIST_PERMISSION.VOUCHER_VIEW,
    component: props => <VoucherList {...props} />,
    display: 1,
  },
  {
    key: "/vouchers/:id",
    label: "Chi tiết voucher",
    title: "Chi tiết voucher",
    component: props => <VoucherDetail {...props} />,
    action_key: LIST_PERMISSION.VOUCHER_VIEW,
  },
  {
    key: "/vouchers/add",
    label: "Thêm voucher",
    title: "Thêm voucher",
    component: props => <VoucherCreate {...props} />,
    action_key: LIST_PERMISSION.VOUCHER_ADD,
  },
];
export default VOUCHER;
