import { Button, Popconfirm, Space } from "antd";
import React from "react";

const ActionForm = ({ form, loading, isDetail, handleReset = ()=>null }) => {
  const onReturn = () => {
    form?.resetFields();
    handleReset()
  };
  return (
    <div className="fixed-action-bar">
      <div />
      <Space>
        <Popconfirm
          title="Bạn có chắc hủy bỏ?"
          onConfirm={() => onReturn()}
          okText="Có"
          cancelText="Không"
          placement="topRight"
          disabled={loading}
        >
          <Button danger>Hủy bỏ</Button>
        </Popconfirm>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          {isDetail ? "Cập nhật" : "Thêm mới"}
        </Button>
      </Space>
    </div>
  );
};

export default ActionForm;
