import { NAVIGATE_LIST } from "_constants";
import _ from "lodash";
import NotPermission from "shared_components/NotPermission";
// import Error from "shared_components/Error";

export const _nav = [].concat(
  NAVIGATE_LIST.DASHBOARD,                    // Dashboard
  NAVIGATE_LIST.ORDER,                        // Quan ly don hang
  NAVIGATE_LIST.PRODUCT_MANAGEMENT,           // Quan ly san pham
  NAVIGATE_LIST.MEMBER_MANAGEMENT,            // Quan ly thanh vien
  NAVIGATE_LIST.VOUCHER,                      // Voucher
  NAVIGATE_LIST.PROMOTION,                    // Quan ly khuyen mai
  NAVIGATE_LIST.SHIPPING_FEE,                 // Quan li phi van chuyen
  NAVIGATE_LIST.QUESTION,                     // Cau hoi khao sat
  NAVIGATE_LIST.GUEST,                        // Khach vang lai
  NAVIGATE_LIST.MEDITATION_POST,              // Thien ca phe
  NAVIGATE_LIST.MEDITATION_DISCOVERY,         // Khai pha Thien ca phe
  NAVIGATE_LIST.MEDITATION_SUBLIMATE,         // Thang hoa cung Thien ca phe
  NAVIGATE_LIST.MEDITATION_OPEN,              // Khai mo Than tam tri
  NAVIGATE_LIST.MEDITATION_HEAL,              // Chua lanh Than Tam tri
  NAVIGATE_LIST.MEDITATION_NEWS,              // Ban tin Thien ca phe
  NAVIGATE_LIST.MEDITATION_AWAKENING,         // Hanh trinh tinh thuc ca nhan
  NAVIGATE_LIST.AWAKENING_COMMUNITY,          // Cong dong tinh thuc
  NAVIGATE_LIST.GAME_MANAGEMENT,              // Tro choi
  NAVIGATE_LIST.LIBRARY_MANAGEMENT,           // Thu vien
  NAVIGATE_LIST.NOTIFICATION_MANAGEMENT,      // Quan ly thong bao
  NAVIGATE_LIST.USER_MANAGEMENT,              // Quan ly nguoi dung
  NAVIGATE_LIST.VERSION_MANAGEMENT,           // Quan ly he thong
  NAVIGATE_LIST.STICKER_MANAGEMENT            // Quan ly sticker
);

export const getNavigation = ({
  navs = _.cloneDeep(_nav),
  listPer = {},
} = {}) => {
  return navs.reduce(
    (result, nav) => {
      let routeArr = _.cloneDeep(result.route);
      let menuArr = _.cloneDeep(result.menu);
      let isDisplayForNav = !!nav.display;

      //Neu nhu co children thi luon push vao trong menu. neu khong thi kiem tra quyen
      let isPermission = nav?.children ? true : !!listPer?.[nav.action_key];
      if (isPermission) {
        routeArr.push(nav);
        if (isDisplayForNav) {
          menuArr.push(nav);
        }
      } else {
        routeArr.push({ ...nav, component: NotPermission });
      }

      if (nav?.children?.length > 0) {
        // recursive childrens
        let childNavs = getNavigation({
          navs: nav?.children,
          listPer: listPer,
        });
        if (!nav.component) routeArr.splice(routeArr.length - 1);
        routeArr = [...routeArr, ...childNavs.route];

        if (childNavs.menu.length > 0) {
          menuArr[menuArr.length - 1].children = [...childNavs.menu];
        } else {
          delete menuArr[menuArr.length - 1];
        }
      }
      return { route: _.compact(routeArr), menu: _.compact(menuArr) };
    },
    { route: [], menu: [] }
  );
};

export default _nav;
