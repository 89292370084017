import { LIST_PERMISSION } from "./Permission/permission";

const typeFile = {
  images: {
    id: "images",
    pathname: "library-images",
    name: "Hình ảnh",
    code: undefined,
    key: 0,
    action_key: {
      add: LIST_PERMISSION.LIBRARY_OTHER_ADD,
      export: LIST_PERMISSION.LIBRARY_OTHER_EXPORT,
      update: LIST_PERMISSION.LIBRARY_OTHER_UPDATE,
      view: LIST_PERMISSION.LIBRARY_OTHER_VIEW,
    },
  },
  video: {
    id: "video",
    pathname: "library-video",
    name: "Video",
    code: "LC_VIDEO",
    key: 2,
    action_key: {
      add: LIST_PERMISSION.LIBRARY_VIDEO_ADD,
      export: LIST_PERMISSION.LIBRARY_VIDEO_EXPORT,
      update: LIST_PERMISSION.LIBRARY_VIDEO_UPDATE,
      view: LIST_PERMISSION.LIBRARY_VIDEO_VIEW,
    },
  },
  audio: {
    id: "audio",
    pathname: "library-audio",
    name: "Audio",
    code: "LC_AUDIO",
    key: 3,
    action_key: {
      add: LIST_PERMISSION.LIBRARY_AUDIO_ADD,
      export: LIST_PERMISSION.LIBRARY_AUDIO_EXPORT,
      update: LIST_PERMISSION.LIBRARY_AUDIO_UPDATE,
      view: LIST_PERMISSION.LIBRARY_AUDIO_VIEW,
    },
  },
  book: {
    id: "book",
    pathname: "library-book",
    name: "Sách",
    code: "LC_BOOK",
    key: 1,
    action_key: {
      add: LIST_PERMISSION.LIBRARY_BOOK_ADD,
      export: LIST_PERMISSION.LIBRARY_BOOK_EXPORT,
      update: LIST_PERMISSION.LIBRARY_BOOK_UPDATE,
      view: LIST_PERMISSION.LIBRARY_BOOK_VIEW,
    },
  },
  other: {
    id: "other",
    pathname: "library-other",
    name: "Tài liệu khác",
    code: "LC_OTHER",
    key: 4,
    action_key: {
      add: LIST_PERMISSION.LIBRARY_OTHER_ADD,
      export: LIST_PERMISSION.LIBRARY_OTHER_EXPORT,
      update: LIST_PERMISSION.LIBRARY_OTHER_UPDATE,
      view: LIST_PERMISSION.LIBRARY_OTHER_VIEW,
    },
  },
};

const statusLibrary = {
  waiting: {
    id: 1,
    key: "1",
    name: "Chờ kiểm duyệt",
    color: "blue",
  },
  active: { id: 2, key: "2", name: "Đã kiểm duyệt", color: "green" },
  unactive: {
    id: -2,
    key: "-2",
    name: "Từ chối kiểm duyệt",
    color: "orange",
  },
  deleted: {
    id: 3,
    key: "3",
    name: "Tạm ngưng",
    color: "red",
  },
};
const listStatusLibrary = Object.values(statusLibrary);
const findStatusLibrary = (id, field) => {
  const result = listStatusLibrary.find(e => e.id === id * 1);
  return result?.[field] || result;
};

export { typeFile, statusLibrary, listStatusLibrary, findStatusLibrary };
