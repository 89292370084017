import {
  CloudUploadOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, message, Upload } from "antd";
import libraryAPI from "apis/Library/libraryAPI";
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { defaultPagination, storage } from "_constants";
import { statusLibrary, typeFile } from "_constants/library";
import ModalLibrary from "./ModalLibrary";

const CropUploader = ({
  type,
  initImageURL,
  className,
  isDisabled,
  imgClassName,
  // onChange,
  isLibrary,
  updateForm,
}) => {
  const [imageURL, setImageURL] = useState("");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const token = localStorage.getItem(storage.ACCESS_TOKEN);
  useEffect(() => {
    if (initImageURL?.aws_name) {
      setImageURL(
        process.env.REACT_APP_MEDIA_READ_FILE + "/" + initImageURL?.aws_name
      );
    }
  }, [initImageURL]);
  const checkType = type => {
    const arr = {
      PDF: "book",
      book: "book",
      image: "other",
      audio: "audio",
      other: "other",
      video: "video",
    };
    return arr?.[type];
  };
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const createLibraryMutation = useMutation(
    ({ payload }) => libraryAPI.create(payload),
    {
      onError: () => {
        message.error(`Thêm file vào thư viện thất bại!`);
      },
      onSuccess: res => {
        message.success(`Thêm file vào thư viện thành công!`);
        setData(res);
      },
    }
  );
  const beforeUpload = file => {
    const isJpgOrPng = file.type?.includes("image/");
    const isOgg = file.type.includes("audio/");
    const isMP4 = file.type.includes("video/");
    const isPdf = file.type === "application/pdf";

    if (isJpgOrPng && (type === "image" || type === "other")) {
      return true;
    }
    if (isOgg && type === "audio") {
      return true;
    }
    if (isMP4 && type === "video") {
      return true;
    }
    if (isPdf && (type === "PDF" || type === "book")) {
      return true;
    }
    message.error(`You can only upload ${type} file!`);
    return false;
  };

  const handleChange = info => {
    const status = info.file.status;
    // const imageId = info.file.response?.id || null;

    if (status === "uploading") {
      setLoading(true);
      return;
    }

    if (status === "done") {
      getBase64(info.file.originFileObj, imgURL => {
        setImageURL(imgURL);
      });
      message.success(`Upload ${type} thành công!`);
    }
    setLoading(false);
  };

  const renderType = (type, link) => {
    if (type === "image" || type === "other") {
      return <img alt="img-upload" src={link} className="img-upload" />;
    }
    if (type === "video") {
      return (
        <video key={link} width="400" height="200" controls>
          <track default kind="captions"></track>
          <source src={link} type="video/mp4" />
        </video>
      );
    }
    if (type === "audio") {
      return (
        <audio key={link} width="400" height="200" controls className="mt-3">
          <track default kind="captions"></track>
          <source src={link} type="audio/ogg" />
        </audio>
      );
    }
    if (type === "PDF" || type === "book") {
      return (
        <div>
          <a href={link} target="_blank" rel="noreferrer">
            {data?.file_info?.name || initImageURL?.name || "Xem file PDF"}
          </a>
        </div>
      );
    }
    return;
  };

  const customRequest = async options => {
    const { onSuccess, file } = options;
    const fmData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        authorization: `Bearer ${token}`,
      },
    };
    fmData.append("file", file);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_MEDIA_UP_FILE}/files`,
        fmData,
        config
      );
      if (res.status === 200) {
        const result = res?.data;

        //Them file vao thu vien
        if (!isLibrary) {
          createLibraryMutation.mutate({
            payload: {
              library_id: typeFile?.[checkType(type)].key,
              name: res?.data?.name,
              status: statusLibrary.waiting.id,
              file_info: {
                name: res?.data?.name,
                aws_name: res?.data?.aws_name,
              },
            },
          });
        }
        updateForm && updateForm(result, "add");
      }
      onSuccess("Ok");
    } catch (err) {
      console.log(err);
    }
  };
  const renderAccept = type => {
    const listAccept = {
      image: "image/*",
      other: "image/*",
      book: "application/pdf",
      audio: "audio/mpeg",
      video: "video/mp4",
    };
    return listAccept[type];
  };
  const uploadProps = {
    listType: "picture-card",
    multiple: false,
    disabled: loading || isDisabled,
    headers: {
      authorization: `Bearer ${token}`,
    },
    showUploadList: false,
    accept: renderAccept(type),
    onChange: handleChange,
    beforeUpload: beforeUpload,
    customRequest: customRequest,
  };
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleSelect = values => {
    setOpenModal(false);
    setImageURL(
      process.env.REACT_APP_MEDIA_READ_FILE + "/" + values?.file_info?.aws_name
    );
    setData(values);
    updateForm && updateForm(values?.file_info, "add");
  };
  return (
    <>
      <div
        style={{
          textAlign: "center",
          width: "100%",
        }}
        className={"crop-uploader-button " + className}
      >
        <CloudUploadOutlined className="color-text-sub me-2" />
        {!isLibrary && (
          <>
            <Button
              disabled={isDisabled}
              type="text"
              className="color-text-sub"
              style={{ fontSize: "13px", padding: 0 }}
              onClick={handleOpenModal}
            >
              Tải lên từ Thư viện
            </Button>
            <span> hoặc </span>
          </>
        )}

        <Upload {...uploadProps}>
          <span className="color-text-sub" style={{ fontSize: "13px" }}>
            Tải lên từ Thiết bị của bạn
          </span>
        </Upload>
      </div>
      <div
        className={
          "crop-uploader-img d-flex justify-content-center align-items-center " +
          imgClassName
        }
        style={{
          height: "180px",
          overflow: "hidden",
          background: "#ebebeb70",
        }}
      >
        {!imageURL ? (
          <Fragment>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div className={"ant-upload-text ms-1 "}>
              Chọn {type === "image" ? "hình ảnh" : type}
            </div>
          </Fragment>
        ) : (
          <Fragment>
            {loading ? <LoadingOutlined /> : renderType(type, imageURL)}
          </Fragment>
        )}
      </div>
      <ModalLibrary
        openModal={openModal}
        setOpenModal={setOpenModal}
        pagination={defaultPagination}
        type={type}
        handleSelect={handleSelect}
      />
    </>
  );
};

export default CropUploader;
