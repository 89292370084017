const statusVoucher = {
  new: {
    id: 1,
    key: "1",
    name: "Mới tạo",
    color: "blue",
    disabled: true,
    action_key: [
      {
        id: 1,
        key: "1",
        name: "Mới tạo",
        color: "blue",
        disabled: true,
      },
      {
        id: 2,
        key: "2",
        name: "Đang hoạt động",
        color: "green",
      },
      {
        id: -2,
        key: "-2",
        name: "Ngưng hoạt động",
        color: "red",
      },
    ],
  },
  active: {
    id: 2,
    key: "2",
    name: "Đang hoạt động",
    color: "green",
    action_key: [
      {
        id: 2,
        key: "2",
        name: "Đang hoạt động",
        color: "green",
      },
      {
        id: -2,
        key: "-2",
        name: "Ngưng hoạt động",
        color: "red",
      },
    ],
  },
  unactive: {
    id: -2,
    key: "-2",
    name: "Ngưng hoạt động",
    color: "red",
    action_key: [
      {
        id: 2,
        key: "2",
        name: "Đang hoạt động",
        color: "green",
      },
      {
        id: -2,
        key: "-2",
        name: "Ngưng hoạt động",
        color: "red",
      },
    ],
  },
  expired: {
    id: -3,
    key: "-3",
    name: "Hết hạn",
    color: "purple",
    action_key: [
      {
        id: -3,
        key: "-3",
        name: "Hết hạn",
        color: "purple",
      },
    ],
    disabled: true,
  },
};

const listStatusVoucher = Object.values(statusVoucher);
const findStatusVoucher = (id, field) => {
  const result = listStatusVoucher.find(e => e.id === id * 1);
  return result?.[field] || result;
};

const typeVoucher = {
  discountPercent: {
    id: 1,
    key: "1",
    name: "Voucher giảm theo tỉ lệ %",
    text: "Tỉ lệ giảm giá",
    unit: "%",
    type_key: "discount",
  },
  discount: {
    id: 2,
    key: "2",
    name: "Voucher giảm giá trị sản phẩm",
    text: "Giá trị Voucher",
    unit: "đồng",
    type_key: "value",
  },
  freeship: {
    id: 3,
    key: "3",
    name: "Voucher giảm phí vận chuyển",
    text: "Giá trị Voucher",
    unit: "đồng",
    type_key: "value",
  },
  giveSeeds: {
    id: 4,
    key: "4",
    name: "Voucher tặng hạt cà phê",
    text: "Số hạt cà phê",
    unit: "hạt",
    type_key: "point",
    disabled: true,
  },
  giveProduct: {
    id: 5,
    key: "5",
    name: "Voucher tặng sản phẩm",
    text: "",
    unit: "",
    type_key: "",
    disabled: true,
  },
};
const listTypeVoucher = Object.values(typeVoucher);
const findTypeVoucher = (id, field) => {
  const result = listTypeVoucher.find(e => e.id === id * 1);
  return result?.[field] || result;
};

const typeObject = {
  all: {
    id: 1,
    key: "1",
    name: "Tất cả",
  },
  dynamic: {
    id: 2,
    key: "2",
    name: "Tùy chỉnh",
  },
  group_member: {
    id: 3,
    key: "3",
    name: "Nhóm thành viên",
  },
};

const listTypeObject = Object.values(typeObject);
const findTypeObject = (id, field) => {
  const result = listTypeObject.find(e => e.id === id * 1);
  return result?.[field] || result;
};

const typePay = {
  male: {
    id: 1,
    key: "1",
    name: "COD",
  },
  female: {
    id: 2,
    key: "2",
    name: "Alepay",
  },
  all: {
    id: 3,
    key: "3",
    name: "Tất cả",
  },
};

const listTypePay = Object.values(typePay);
const findTypePay = (id, field) => {
  const result = listTypePay.find(e => e.id === id * 1);
  return result?.[field] || result;
};

const typeProduct = {
  all: {
    id: 1,
    key: "1",
    name: "Tất cả",
  },
  dynamic: {
    id: 2,
    key: "2",
    name: "Tùy chỉnh",
  },
};

const listTypeProduct = Object.values(typeProduct);
export {
  statusVoucher,
  listStatusVoucher,
  findStatusVoucher,
  typeVoucher,
  listTypeVoucher,
  findTypeVoucher,
  listTypeObject,
  typeObject,
  findTypeObject,
  typePay,
  listTypePay,
  findTypePay,
  listTypeProduct,
  typeProduct,
};
