import { Select } from "antd";
import _, { cloneDeep, isArray } from "lodash";
import moment from "moment";
import { transformDateToString } from "./date";
import numeral from "numeral";

//Convert type Datetime to moment => Antd Datepicker can read
export const formatValueFilter = filter => {
  let arrayKey = [
    "birthday",
    "created_at",
    "created_from",
    "created_to",
    "scheduling_time_from",
    "scheduling_time_To",
    "start_at_from",
    "start_at_to",
  ];
  let cloneFilter = _.cloneDeep(filter);
  for (const property in cloneFilter) {
    if (arrayKey.includes(property) && cloneFilter[property]) {
      cloneFilter[property] = transformDateToString(cloneFilter[property]);
    }
  }
  return cloneFilter;
};
export const formatFilterBeforeSyncURL = filter => {
  const newFilter = { ...filter };
  let dateKeys = [
    "birthday",
    "created_from",
    "created_to",
    "scheduling_time_from",
    "scheduling_time_To",
    "start_at_from",
    "start_at_to",
  ];

  dateKeys.forEach(dateKey => {
    if (newFilter[dateKey]) {
      newFilter[dateKey] = transformDateToString(newFilter[dateKey]);
    }
  });

  return newFilter;
};

export const formatValueData = filter => {
  let arrayKey = [
    "birthday",
    "created_at",
    "created_from",
    "created_to",
    "updated_at",
    "scheduling_time_from",
    "scheduling_time_To",
    "start_at",
    "end_at",
    "end_time",
    "start_time",
    "price_update_date",
  ];
  let cloneFilter = _.cloneDeep(filter);
  for (const property in cloneFilter) {
    if (arrayKey.includes(property) && cloneFilter[property]) {
      cloneFilter[property] = moment(cloneFilter[property]);
    }
  }
  return cloneFilter;
};
export const checkDisableDateInfo = (
  date,
  field,
  form,
  type,
  disableBeforeCurrent = true,
  disableSameDay
) => {
  if (disableBeforeCurrent && date.valueOf() < moment().valueOf()) {
    return true;
  }
  const dateCompare = form.getFieldValue(field);
  if (!date || !dateCompare) {
    return false;
  }
  if (type === "from") {
    return date.valueOf() > dateCompare.valueOf();
  } else if (type === "to") {
    if (disableSameDay) return date.valueOf() <= dateCompare.valueOf();
    return date.valueOf() < dateCompare.valueOf();
  }
};

export const checkDisableDate = (
  date,
  field,
  form,
  type,
  disableAfterCurrent = true,
  disableSameDay
) => {
  if (disableAfterCurrent && date.valueOf() > moment().valueOf()) {
    return true;
  }
  const dateCompare = form.getFieldValue(field);
  if (!date || !dateCompare) {
    return false;
  }
  if (type === "from") {
    return date.valueOf() > dateCompare.valueOf();
  } else if (type === "to") {
    if (disableSameDay) return date.valueOf() <= dateCompare.valueOf();
    return date.valueOf() < dateCompare.valueOf();
  }
};

export const resetObject = object => {
  const result = {};
  for (let key in object) {
    result[key] = undefined;
  }

  return result;
};
export const numeralNumber = (value, format = "0,0") => {
  return numeral(value).format(format);
};
export const renderOptions = ({
  data,
  keyValue = "id",
  keyDisplay = "name",
  selector,
  disabled = true,
}) => {
  if (data && data.length > 0) {
    return data.map((item, index) => (
      <Select.Option
        disabled={(disabled && item.disabled) || false}
        key={item?.id || index}
        value={item[keyValue]}
      >
        {selector ? selector(item) : item[keyDisplay]}
      </Select.Option>
    ));
  }
};

export const requiredLabel = label => {
  return (
    <span>
      {label} <span style={{ color: "#ff0000ad" }}>*</span>
    </span>
  );
};

export function unAccent(str) {
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
  return str;
}

export const findInArr = (array, value, keyReturn) => {
  if (array && array.length > 0) {
    let result = array.find(item => item.id * 1 === value * 1);
    return result || result?.[keyReturn];
  }
};
export function differentObject(object, base) {
  function changes(object, base) {
    return _.transform(object, function (result, value, key) {
      if (_.isArray(value)) {
        if (!_.isEqual(value, base[key])) {
          result[key] = value;
        }
      } else {
        if (value && Object.keys(base).includes(key)) {
          if (!_.isEqual(value, base[key])) {
            result[key] =
              !(value instanceof Date) &&
              _.isObject(value) &&
              _.isObject(base[key])
                ? changes(value, base[key])
                : value;
          }
        } else {
          result[key] = value;
        }
      }
    });
  }
  return changes(object, base);
}

export const renderReadFile = aws_name => {
  return `${process.env.REACT_APP_MEDIA_READ_FILE}/${aws_name}`;
};

export const getDisabledHours = time => {
  var hours = [];
  const day = moment(time).day();
  const month = moment(time).month();
  const year = moment(time).year();
  if (
    year === moment().year() &&
    month === moment().month() &&
    day === moment().day() &&
    moment(time).hour() === moment().hour()
  ) {
    for (var i = 0; i < moment(time).hour(); i++) {
      hours.push(i);
    }
  }
  return hours;
};

export const getDisabledMinutes = time => {
  var minutes = [];
  const day = moment(time).day();
  const times = moment(time).hour();
  const month = moment(time).month();
  const year = moment(time).year();
  if (
    year === moment().year() &&
    month === moment().month() &&
    day === moment().day() &&
    times === moment().hour()
  ) {
    for (var i = 0; i < moment().minute(); i++) {
      minutes.push(i);
    }
  }
  return minutes;
};
export const getDisabledSeconds = time => {
  var secound = [];
  const day = moment(time).day();
  const hours = moment(time).hour();
  const minutes = moment(time).minute();
  const month = moment(time).month();
  const year = moment(time).year();
  if (
    year === moment().year() &&
    month === moment().month() &&
    day === moment().day() &&
    hours === moment().hour() &&
    minutes === moment().minute()
  ) {
    for (var i = 0; i < moment().second(); i++) {
      secound.push(i);
    }
  }
  return secound;
};
export const cleanObject = filter => {
  const cloneFilter = cloneDeep(filter);
  for (let filterKey in cloneFilter) {
    // process invalid value
    if (
      (isArray(cloneFilter[filterKey]) && cloneFilter[filterKey].length) ===
        0 ||
      cloneFilter[filterKey] === false ||
      cloneFilter[filterKey] === null ||
      cloneFilter[filterKey] === undefined ||
      cloneFilter[filterKey] === "" ||
      cloneFilter[filterKey] === "[]"
    ) {
      delete cloneFilter[filterKey];
      continue;
    }

    if (isArray(cloneFilter[filterKey])) {
      cloneFilter[filterKey] = cloneFilter[filterKey].join(",");
      continue;
    }

    // process date
    if (moment.isMoment(cloneFilter[filterKey])) {
      cloneFilter[filterKey] = moment(cloneFilter[filterKey]).format(
        "YYYY-MM-DD"
      );
      continue;
    }
  }

  return cloneFilter;
};

export const formatMomentToString = (
  obj = {},
  listField = [],
  format = "YYYY-MM-DD HH:mm:ss"
) => {
  const newObj = {
    ...obj,
  };
  listField.forEach(e => {
    if (obj?.[e]) {
      newObj[e] = moment(obj[e]).format(format);
    }
  });
  return newObj;
};
