import Icon from "@ant-design/icons";

import { listPathname } from "_constants/ListPathname/pathname";
import { LIST_PERMISSION } from "_constants/Permission/permission";
import { product } from "layouts/Icon";
import {
  GroupProductCreate,
  GroupProductDetail,
  GroupProductList,
  ProductCreate,
  ProductDetail,
  ProductList,
} from "./lazy-import";

const PRODUCT_MANAGEMENT = [
  {
    key: listPathname.manager_product.sub,
    title: "Quản lý sản phẩm",
    label: "Quản lý sản phẩm",
    icon: <Icon component={product} />,
    display: 1,
    children: [
      {
        key: listPathname.manager_product.groups.list,
        title: "Nhóm sản phẩm",
        label: "Nhóm sản phẩm",
        component: props => <GroupProductList {...props} />,
        action_key: LIST_PERMISSION.PRODUCT_GROUP_VIEW,
        display: 1,
      },
      {
        key: listPathname.manager_product.groups.add,
        title: "Tạo sản phẩm",
        label: "Tạo sản phẩm",
        component: props => <GroupProductCreate {...props} />,
        action_key: LIST_PERMISSION.PRODUCT_GROUP_ADD,
      },
      {
        key: listPathname.manager_product.groups.detail,
        title: "Chi tiết sản phẩm",
        label: "Chi tiết sản phẩm",
        component: props => <GroupProductDetail {...props} />,
        action_key: LIST_PERMISSION.PRODUCT_GROUP_VIEW,
      },
      {
        key: listPathname.manager_product.products.list,
        title: "Danh sách sản phẩm",
        label: "Danh sách sản phẩm",
        component: props => <ProductList {...props} />,
        action_key: LIST_PERMISSION.PRODUCT_VIEW,
        display: 1,
      },
      {
        key: listPathname.manager_product.products.add,
        title: "Tạo sản phẩm",
        label: "Tạo sản phẩm",
        component: props => <ProductCreate {...props} />,
        action_key: LIST_PERMISSION.PRODUCT_ADD,
      },
      {
        key: listPathname.manager_product.products.detail,
        title: "Chi tiết sản phẩm",
        label: "Chi tiết sản phẩm",
        component: props => <ProductDetail {...props} />,
        action_key: LIST_PERMISSION.PRODUCT_VIEW,
      },
      // {
      //   key: listPathname.manager_product.vat.list,
      //   title: "Tỉ lệ VAT",
      //   label: "Tỉ lệ VAT",
      //   component: props => <VatManagement {...props} />,
      //   action_key: LIST_PERMISSION.VAT_DETAIL,
      //   display: 1,
      // },
    ],
  },
];

export default PRODUCT_MANAGEMENT;
