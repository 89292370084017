import { storage } from "_constants";
import { getNavigation } from "_nav";
import { useMemo } from "react";

const storagePermission = () => localStorage[storage.PERMISSION];

const useNav = () => {
  const permission = storagePermission() && JSON.parse(storagePermission());

  return useMemo(() => {
    return getNavigation({ listPer: permission });
  }, [permission]);
};

export default useNav;
