import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider } from "react-redux";
import reportWebVitals from "reportWebVitals";
import App from "./App";
import { store } from "store";
import "./index.css";
import { Loading } from "shared_components";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { storage } from "_constants";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2,
      retryDelay: 3000,
    },
  },
});

const cssThemeLinks = {
  light: "/light-theme.css",
  dark: "/dark-theme.css",
};

const prefersDarkMode = window.matchMedia(
  "(prefers-color-scheme: dark)"
).matches;

const initDarkMode = localStorage.getItem(storage.DARK_MODE)
  ? JSON.parse(localStorage.getItem(storage.DARK_MODE))
  : prefersDarkMode;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Suspense fallback={<Loading />}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <ThemeSwitcherProvider
            defaultTheme={initDarkMode ? "dark" : "light"}
            themeMap={cssThemeLinks}
          >
            <App />
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          </ThemeSwitcherProvider>
        </QueryClientProvider>
      </Provider>
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
