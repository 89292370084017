import { Card } from "antd";
import React from "react";

function CommonCard({ title, children, ...rest }) {
  return (
    <Card
      title={<p className="fw-bold fs-6 mb-0 text-uppercase">{title}</p>}
      {...rest}
    >
      {children}
    </Card>
  );
}

CommonCard.propTypes = {};

export default CommonCard;
