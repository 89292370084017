const statusCategory = {
  active: {
    id: 1,
    key: "1",
    name: "Đang hoạt động",
    color: "green",
  },
  unActive: {
    id: -1,
    key: "-1",
    name: "Chưa hoạt động",
    color: "red",
  },
};
const listStatusCategory = Object.values(statusCategory);
const findStatusCategory = (id, field) => {
  const result = listStatusCategory.find(e => e.id === id * 1);
  return result?.[field] || result;
};

const typeCategory = {
  static: {
    id: 1,
    key: "1",
    name: "Mặc định",
    color: "green",
  },
  dynamic: {
    id: 2,
    key: "2",
    name: "Tùy chỉnh",
    color: "red",
  },
};
const listTypeCategory = Object.values(typeCategory);
export {
  listStatusCategory,
  findStatusCategory,
  statusCategory,
  typeCategory,
  listTypeCategory,
};
