import Icon from "@ant-design/icons";

import { listPathname } from "_constants/ListPathname/pathname";
import { LIST_PERMISSION } from "_constants/Permission/permission";
import { member } from "layouts/Icon";
import {
  MemberDetail,
  MemberGroup,
  MemberGroupCreate,
  MemberGroupDetail,
  MemberList,
} from "./lazy-import";

const MEMBER_MANAGEMENT = [
  {
    key: listPathname.manager_member.sub,
    title: "Quản lý thành viên",
    label: "Quản lý thành viên",
    icon: <Icon component={member} />,
    display: 1,
    children: [
      {
        key: listPathname.manager_member.groups.list,
        title: "Nhóm thành viên",
        label: "Nhóm thành viên",
        component: props => <MemberGroup {...props} />,
        action_key: LIST_PERMISSION.MEMBER_GROUP_VIEW,
        display: 1,
      },
      {
        key: listPathname.manager_member.groups.add,
        title: "Thêm nhóm thành viên",
        label: "Thêm nhóm thành viên",
        component: props => <MemberGroupCreate {...props} />,
        action_key: LIST_PERMISSION.MEMBER_GROUP_ADD,
      },
      {
        key: listPathname.manager_member.groups.details,
        title: "Chi tiết nhóm thành viên",
        label: "Chi tiết nhóm thành viên",
        component: props => <MemberGroupDetail {...props} />,
        action_key: LIST_PERMISSION.MEMBER_GROUP_VIEW,
      },
      {
        key: listPathname.manager_member.members.list,
        title: "Danh sách thành viên",
        label: "Danh sách thành viên",
        component: props => <MemberList {...props} />,
        action_key: LIST_PERMISSION.MEMBER_VIEW,
        display: 1,
      },
      {
        key: listPathname.manager_member.members.detail,
        title: "Chi tiết thành viên",
        label: "Chi tiết thành viên",
        component: props => <MemberDetail {...props} />,
        action_key: LIST_PERMISSION.MEMBER_VIEW,
      },
    ],
  },
];
export default MEMBER_MANAGEMENT;
