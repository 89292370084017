import { cloneDeep } from "lodash";
import queryString from "query-string";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { formatFilterBeforeSyncURL, formatValueFilter } from "utils/common";
import { defaultPagination } from "_constants";
const removeEmpty = obj => {
  let newObj = {};
  Object.keys(obj).forEach(key => {
    if (obj[key] === Object(obj[key])) newObj[key] = removeEmpty(obj[key]);
    else if (typeof obj[key] === "string") {
      if (obj[key].trim().length !== 0) {
        newObj[key] = obj[key];
      }
    } else if (obj[key] !== undefined) newObj[key] = obj[key];
  });
  return newObj;
};

function useFilter(filterSchema) {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = queryString.parse(searchParams.toString());

  const filter = useMemo(() => {
    const queryParams = queryString.parse(searchParams.toString());
    filterSchema?.forEach(item => {
      queryParams[item.name] = queryParams[item.name]
        ? item.type
          ? item.type(queryParams[item.name])
          : queryParams[item.name]
        : item.default;

      if (item.type === String) {
        queryParams[item.name] = queryParams?.[item?.name]?.trim();
      }
    });
    return queryParams;
  }, [params]);

  const apiFilter = useMemo(() => {
    return formatFilterBeforeSyncURL(filter);
  }, [filter]);

  const handleResetFilter = objReset => {
    const defaultValue = {};
    filterSchema.forEach(e => {
      if (e?.default) {
        defaultValue[e?.name] = e?.default;
      }
    });
    if (objReset && Object.keys(objReset).length > 0) {
      setSearchParams({ ...defaultValue, ...objReset });
      return;
    }
    setSearchParams(defaultValue);
  };

  const handleFilterChange = newFilter => {
    const cloneFilter = cloneDeep(newFilter);
    if (!cloneFilter.page) {
      cloneFilter.page = defaultPagination.page;
    }
    if (!cloneFilter.perPage) {
      cloneFilter.perPage = defaultPagination.perPage;
    }
    setSearchParams(removeEmpty(formatValueFilter(cloneFilter)));
  };
  return {
    filter,
    apiFilter,
    onFilterReset: handleResetFilter,
    onFilterChange: handleFilterChange,
  };
}

export default useFilter;
