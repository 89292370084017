import { lazy } from "react";

export const Dashboard = lazy(() => import("containers/Dashboard/Dashboard"));

export const MemberList = lazy(() => import("containers/Member/MemberList"));
export const MemberDetail = lazy(() =>
  import("containers/Member/MemberDetail")
);
export const MemberGroup = lazy(() =>
  import("containers/Member/MemberGroupList")
);
export const MemberGroupCreate = lazy(() =>
  import("containers/Member/MemberGroupCreate")
);
export const MemberGroupDetail = lazy(() =>
  import("containers/Member/MemberGroupDetail")
);

export const UserList = lazy(() => import("containers/User/UserList"));
export const UserDetail = lazy(() => import("containers/User/UserDetail"));
export const UserCreate = lazy(() => import("containers/User/UserCreate"));

export const SurveyList = lazy(() =>
  import("containers/QuestionSurvey/Survey/SurveyList")
);
export const SurveyDetail = lazy(() =>
  import("containers/QuestionSurvey/Survey/SurveyDetail")
);
export const SurveyCreate = lazy(() =>
  import("containers/QuestionSurvey/Survey/SurveyCreate")
);

export const QuestionList = lazy(() =>
  import("containers/QuestionSurvey/Question/QuestionList")
);
export const QuestionDetail = lazy(() =>
  import("containers/QuestionSurvey/Question/QuestionDetail")
);
export const QuestionCreate = lazy(() =>
  import("containers/QuestionSurvey/Question/QuestionCreate")
);

export const MeditationCoffeePostList = lazy(() =>
  import("containers/MeditationCoffeePost/MeditationCoffeePostList")
);
export const MeditationCoffeePostDetail = lazy(() =>
  import("containers/MeditationCoffeePost/MeditationCoffeePostDetail")
);
export const MeditationCoffeePostCreate = lazy(() =>
  import("containers/MeditationCoffeePost/MeditationCoffeePostCreate")
);

export const BMIList = lazy(() => import("containers/MCDiscover/BMI/BMIList"));
export const BMIDetail = lazy(() =>
  import("containers/MCDiscover/BMI/BMIDetail")
);

export const BMIResultList = lazy(() =>
  import("containers/MCDiscover/BMI/BMIResult")
);

export const BookList = lazy(() =>
  import("containers/MCDiscover/MCDiscoverBook/MCDiscoverBookList")
);
export const BookDetail = lazy(() =>
  import("containers/MCDiscover/MCDiscoverBook/MCDiscoverBookDetail")
);
export const BookCreate = lazy(() =>
  import("containers/MCDiscover/MCDiscoverBook/MCDiscoverBookCreate")
);

export const MindTrainingBookList = lazy(() =>
  import("containers/MCSublime/MindTrainingBook/MindTrainingBookList")
);
export const MindTrainingBookCreate = lazy(() =>
  import("containers/MCSublime/MindTrainingBook/MindTrainingBookCreate")
);
export const MindTrainingBookDetail = lazy(() =>
  import("containers/MCSublime/MindTrainingBook/MindTrainingBookDetail")
);

export const MCDiscoverPostList = lazy(() =>
  import("containers/MCDiscover/MCDiscoverPost/MCDiscoverPostList")
);
export const MCDiscoverPostDetail = lazy(() =>
  import("containers/MCDiscover/MCDiscoverPost/MCDiscoverPostDetail")
);
export const MCDiscoverPostCreate = lazy(() =>
  import("containers/MCDiscover/MCDiscoverPost/MCDiscoverPostCreate")
);

export const BodyForgingCategoryList = lazy(() =>
  import(
    "containers/MCSublime/BodyForging/BodyForgingCategory/BodyForgingCategoryList"
  )
);
export const BodyForgingCategoryDetail = lazy(() =>
  import(
    "containers/MCSublime/BodyForging/BodyForgingCategory/BodyForgingCategoryDetail"
  )
);
export const BodyForgingCategoryCreate = lazy(() =>
  import(
    "containers/MCSublime/BodyForging/BodyForgingCategory/BodyForgingCategoryCreate"
  )
);

export const BodyForgingPostList = lazy(() =>
  import("containers/MCSublime/BodyForging/BodyForgingPost/BodyForgingPostList")
);
export const BodyForgingPostDetail = lazy(() =>
  import(
    "containers/MCSublime/BodyForging/BodyForgingPost/BodyForgingPostDetail"
  )
);
export const BodyForgingPostCreate = lazy(() =>
  import(
    "containers/MCSublime/BodyForging/BodyForgingPost/BodyForgingPostCreate"
  )
);

export const NurturingTheMindList = lazy(() =>
  import("containers/MCSublime/NurturingTheMindPost/NurturingTheMindList")
);
export const NurturingTheMindDetail = lazy(() =>
  import("containers/MCSublime/NurturingTheMindPost/NurturingTheMindDetail")
);
export const NurturingTheMindCreate = lazy(() =>
  import("containers/MCSublime/NurturingTheMindPost/NurturingTheMindCreate")
);

export const EatHealthyPostList = lazy(() =>
  import("containers/MCDiscover/EatHealthyPost/EatHealthyPostList")
);
export const EatHealthyPostDetail = lazy(() =>
  import("containers/MCDiscover/EatHealthyPost/EatHealthyPostDetail")
);
export const EatHealthyPostCreate = lazy(() =>
  import("containers/MCDiscover/EatHealthyPost/EatHealthyPostCreate")
);

export const LifeChangingMovieCabinetList = lazy(() =>
  import(
    "containers/OpenBodyHeartMind/LifeChangingMovieCabinet/LifeChangingMovieCabinetList"
  )
);
export const LifeChangingMovieCabinetDetail = lazy(() =>
  import(
    "containers/OpenBodyHeartMind/LifeChangingMovieCabinet/LifeChangingMovieCabinetDetail"
  )
);
export const LifeChangingMovieCabinetCreate = lazy(() =>
  import(
    "containers/OpenBodyHeartMind/LifeChangingMovieCabinet/LifeChangingMovieCabinetCreate"
  )
);

export const BookshelfIsTheFoundationList = lazy(() =>
  import(
    "containers/OpenBodyHeartMind/BookshelfIsTheFoundation/BookshelfFoundationList"
  )
);
export const BookshelfIsTheFoundationDetail = lazy(() =>
  import(
    "containers/OpenBodyHeartMind/BookshelfIsTheFoundation/BookshelfFoundationDetail"
  )
);
export const BookshelfIsTheFoundationCreate = lazy(() =>
  import(
    "containers/OpenBodyHeartMind/BookshelfIsTheFoundation/BookshelfFoundationCreate"
  )
);

export const MindTrainingPostList = lazy(() =>
  import("containers/MCSublime/MindTrainingPost/MindTrainingPostList")
);
export const MindTrainingPostDetail = lazy(() =>
  import("containers/MCSublime/MindTrainingPost/MindTrainingPostDetail")
);
export const MindTrainingPostCreate = lazy(() =>
  import("containers/MCSublime/MindTrainingPost/MindTrainingPostCreate")
);
export const BartenderGuidePostList = lazy(() =>
  import(
    "containers/OpenBodyHeartMind/BartenderGuidePost/BartenderGuidePostList"
  )
);
export const BartenderGuidePostDetail = lazy(() =>
  import(
    "containers/OpenBodyHeartMind/BartenderGuidePost/BartenderGuidePostDetail"
  )
);
export const BartenderGuidePostCreate = lazy(() =>
  import(
    "containers/OpenBodyHeartMind/BartenderGuidePost/BartenderGuidePostCreate"
  )
);

export const MeditationMusicPostList = lazy(() =>
  import(
    "containers/OpenBodyHeartMind/MeditationMusicPost/MeditationMusicPostList"
  )
);
export const MeditationMusicPostDetail = lazy(() =>
  import(
    "containers/OpenBodyHeartMind/MeditationMusicPost/MeditationMusicPostDetail"
  )
);
export const MeditationMusicPostCreate = lazy(() =>
  import(
    "containers/OpenBodyHeartMind/MeditationMusicPost/MeditationMusicPostCreate"
  )
);

export const OtherDocumentPostList = lazy(() =>
  import("containers/OpenBodyHeartMind/OtherDocumentPost/OtherDocumentPostList")
);
export const OtherDocumentPostDetail = lazy(() =>
  import(
    "containers/OpenBodyHeartMind/OtherDocumentPost/OtherDocumentPostDetail"
  )
);
export const OtherDocumentPostCreate = lazy(() =>
  import(
    "containers/OpenBodyHeartMind/OtherDocumentPost/OtherDocumentPostCreate"
  )
);

export const OrderList = lazy(() => import("containers/Order/OrderList"));
export const OrderDetail = lazy(() => import("containers/Order/OrderDetail"));

export const PromotionList = lazy(() =>
  import("containers/PromotionManagement/promotion-list")
);
export const PromotionDetail = lazy(() =>
  import("containers/PromotionManagement/promotion-detail")
);
export const PromotionCreate = lazy(() =>
  import("containers/PromotionManagement/promotion-create")
);

// export const LibraryImagesList = lazy(() =>
//   import("containers/Library/Images/ImagesList")
// );
export const LibraryVideoList = lazy(() =>
  import("containers/Library/Video/VideoList")
);
export const LibraryAudioList = lazy(() =>
  import("containers/Library/Audio/AudioList")
);
export const LibraryBookList = lazy(() =>
  import("containers/Library/Book/BookList")
);
export const LibraryOtherList = lazy(() =>
  import("containers/Library/Other/OtherList")
);
export const LibraryDetail = lazy(() =>
  import("containers/Library/LibraryDetail")
);
export const LibraryCreate = lazy(() =>
  import("containers/Library/LibraryCreate")
);

export const ProductList = lazy(() =>
  import("containers/Product/products/ProductList")
);
export const ProductDetail = lazy(() =>
  import("containers/Product/products/ProductDetail")
);
export const ProductCreate = lazy(() =>
  import("containers/Product/products/ProductCreate")
);

export const GroupProductList = lazy(() =>
  import("containers/Product/group-product/group-product-list")
);
export const GroupProductDetail = lazy(() =>
  import("containers/Product/group-product/group-product-detail")
);
export const GroupProductCreate = lazy(() =>
  import("containers/Product/group-product/group-product-create")
);

export const VatManagement = lazy(() =>
  import("containers/Product/vat/vat-management")
);

export const NotificationList = lazy(() =>
  import("containers/Notification/NotificationList")
);
export const NotificationDetail = lazy(() =>
  import("containers/Notification/NotificationDetail")
);
export const NotificationCreate = lazy(() =>
  import("containers/Notification/NotificationCreate")
);

export const VersionManagement = lazy(() =>
  import("containers/Version/VersionManagement")
);

export const SpecialistList = lazy(() =>
  import("containers/HealBodyHeartMind/Specialist/SpecialistList")
);
export const SpecialistDetail = lazy(() =>
  import("containers/HealBodyHeartMind/Specialist/SpecialistDetail")
);
export const SpecialistCreate = lazy(() =>
  import("containers/HealBodyHeartMind/Specialist/SpecialistCreate")
);

export const HealBHMPostCreate = lazy(() =>
  import("containers/HealBodyHeartMind/Post/HealBHMPostCreate")
);
export const HealBHMPostDetail = lazy(() =>
  import("containers/HealBodyHeartMind/Post/HealBHMPostDetail")
);
export const HealBHMPostList = lazy(() =>
  import("containers/HealBodyHeartMind/Post/HealBHMPostList")
);

export const HealBHMTalkshowList = lazy(() =>
  import("containers/HealBodyHeartMind/TalkShow/HealBHMTalkshowList")
);
export const HealBHMTalkshowCreate = lazy(() =>
  import("containers/HealBodyHeartMind/TalkShow/HealBHMTalkshowCreate")
);
export const HealBHMTalkshowDetail = lazy(() =>
  import("containers/HealBodyHeartMind/TalkShow/HealBHMTalkshowDetail")
);

export const TuturialPostCreate = lazy(() =>
  import("containers/HealBodyHeartMind/Tuturial/TuturialPostCreate")
);
export const TuturialPostDetail = lazy(() =>
  import("containers/HealBodyHeartMind/Tuturial/TuturialPostDetail")
);
export const TuturialPostList = lazy(() =>
  import("containers/HealBodyHeartMind/Tuturial/TuturialPostList")
);

export const StickerManagement = lazy(() =>
  import("containers/StickerManager/StickerManagement")
);
export const StickerManagementDetail = lazy(() =>
  import("containers/StickerManager/StickerManagementDetail")
);
export const StickerManagementCreate = lazy(() =>
  import("containers/StickerManager/StickerManagementCreate")
);

export const NewsLetterCreate = lazy(() =>
  import("containers/CoffeeMeditationNewsletter/NewsLetter/NewsLetterCreate")
);
export const NewsLetterDetail = lazy(() =>
  import("containers/CoffeeMeditationNewsletter/NewsLetter/NewsLetterDetail")
);
export const NewsLetterList = lazy(() =>
  import("containers/CoffeeMeditationNewsletter/NewsLetter/NewsLetterList")
);

export const NewProductCreate = lazy(() =>
  import("containers/CoffeeMeditationNewsletter/NewProduct/NewProductCreate")
);
export const NewProductDetail = lazy(() =>
  import("containers/CoffeeMeditationNewsletter/NewProduct/NewProductDetail")
);
export const NewProductList = lazy(() =>
  import("containers/CoffeeMeditationNewsletter/NewProduct/NewProductList")
);

export const EventsCreate = lazy(() =>
  import("containers/CoffeeMeditationNewsletter/Events/EventCreate")
);
export const EventsDetail = lazy(() =>
  import("containers/CoffeeMeditationNewsletter/Events/EventDetail")
);
export const EventsList = lazy(() =>
  import("containers/CoffeeMeditationNewsletter/Events/EventList")
);

export const CommunityShareCreate = lazy(() =>
  import("containers/CommunityAwakening/CommunityShare/CommunityShareCreate")
);
export const CommunityShareDetail = lazy(() =>
  import("containers/CommunityAwakening/CommunityShare/CommunityShareDetail")
);
export const CommunityShareList = lazy(() =>
  import("containers/CommunityAwakening/CommunityShare/CommunityShareList")
);

export const CommunityShareEventCreate = lazy(() =>
  import("containers/CommunityAwakening/Events/EventCreate")
);
export const CommunityShareEventDetail = lazy(() =>
  import("containers/CommunityAwakening/Events/EventDetail")
);
export const CommunityShareEventList = lazy(() =>
  import("containers/CommunityAwakening/Events/EventList")
);

export const SharingExperiencesDetail = lazy(() =>
  import(
    "containers/CommunityAwakening/SharingExperiences/SharingExperiencesDetail"
  )
);
export const SharingExperiencesList = lazy(() =>
  import(
    "containers/CommunityAwakening/SharingExperiences/SharingExperiencesList"
  )
);
export const SharingExperiencesCreate = lazy(() =>
  import(
    "containers/CommunityAwakening/SharingExperiences/SharingExperiencesCreate"
  )
);

export const VoucherList = lazy(() =>
  import("containers/Vouchers/VoucherList")
);
export const VoucherDetail = lazy(() =>
  import("containers/Vouchers/VoucherDetail")
);
export const VoucherCreate = lazy(() =>
  import("containers/Vouchers/VoucherCreate")
);

export const MeditationLevelDetail = lazy(() =>
  import("containers/PersonalAwakening/MeditationLevel/MeditationLevelDetail")
);
export const MeditationLevelList = lazy(() =>
  import("containers/PersonalAwakening/MeditationLevel/MeditationLevelList")
);

export const MeditationMusicDetail = lazy(() =>
  import("containers/PersonalAwakening/MeditationMusic/MeditationMusicDetail")
);
export const MeditationMusicList = lazy(() =>
  import("containers/PersonalAwakening/MeditationMusic/MeditationMusicList")
);
export const MeditationMusicCreate = lazy(() =>
  import("containers/PersonalAwakening/MeditationMusic/MeditationMusicCreate")
);

export const MeditationVideoDetail = lazy(() =>
  import("containers/PersonalAwakening/MeditationVideo/MeditationVideoDetail")
);
export const MeditationVideoList = lazy(() =>
  import("containers/PersonalAwakening/MeditationVideo/MeditationVideoList")
);
export const MeditationVideoCreate = lazy(() =>
  import("containers/PersonalAwakening/MeditationVideo/MeditationVideoCreate")
);
export const GameList = lazy(() =>
  import("containers/GameManager/Games/GameList")
);
export const GameCreate = lazy(() =>
  import("containers/GameManager/Games/GameCreate")
);
export const GameDetail = lazy(() =>
  import("containers/GameManager/Games/GameDetail")
);
export const QuestionGameList = lazy(() =>
  import("containers/GameManager/QuestionGame/QuestionList")
);
export const QuestionGameCreate = lazy(() =>
  import("containers/GameManager/QuestionGame/QuestionCreate")
);
export const QuestionGameDetail = lazy(() =>
  import("containers/GameManager/QuestionGame/QuestionDetail")
);
export const ResultGameList = lazy(() =>
  import("containers/GameManager/ResultGame/ResultGameList")
);
export const ResultGameDetail = lazy(() =>
  import("containers/GameManager/ResultGame/ResultGameDetail")
);
//#region Khach vang lai
export const GuestVideoList = lazy(() =>
  import("containers/ManagerGuest/GuestVideo/GuestVideoList")
);
export const GuestVideoDetail = lazy(() =>
  import("containers/ManagerGuest/GuestVideo/GuestVideoDetail")
);
export const GuestVideoCreate = lazy(() =>
  import("containers/ManagerGuest/GuestVideo/GuestVideoCreate")
);

export const GuestQuestionList = lazy(() =>
  import("containers/ManagerGuest/Question/QuestionList")
);
export const GuestQuestionDetail = lazy(() =>
  import("containers/ManagerGuest/Question/QuestionDetail")
);
export const GuestQuestionCreate = lazy(() =>
  import("containers/ManagerGuest/Question/QuestionCreate")
);

export const GuestSetQuestionList = lazy(() =>
  import("containers/ManagerGuest/SetQuestion/SetQuestionList")
);
export const GuestSetQuestionDetail = lazy(() =>
  import("containers/ManagerGuest/SetQuestion/SetQuestionDetail")
);
export const GuestSetQuestionCreate = lazy(() =>
  import("containers/ManagerGuest/SetQuestion/SetQuestionCreate")
);
export const ProductGuest = lazy(() =>
  import("containers/ManagerGuest/ProductGuest/ProductGuest")
);

export const ManagerShipFeeList = lazy(() =>
  import("containers/management-shipping-fees/ManageShippingFeesList")
);
export const ManagerShipFeeDetail = lazy(() =>
  import("containers/management-shipping-fees/ManageShippingFeesDetail")
);
export const ManagerShipFeeCreate = lazy(() =>
  import("containers/management-shipping-fees/ManageShippingFeesCreate")
);

export const GroupUserList = lazy(() =>
  import("containers/User/GroupUser/GroupUserList")
);
export const GroupUserDetail = lazy(() =>
  import("containers/User/GroupUser/GroupUserDetail")
);
export const GroupUserCreate = lazy(() =>
  import("containers/User/GroupUser/GroupUserCreate")
);
