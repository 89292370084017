import { ApiOutlined } from "@ant-design/icons";
import { Button, Result } from "antd";

export default function NoInternet() {
  function refreshPage() {
    window.location.reload(false);
  }
  return (
    <Result
      icon={<ApiOutlined style={{ color: "#ff000099" }} />}
      title="No internet connection"
      subTitle="Your connection appears to be offline. Try to refresh the page"
      extra={
        <Button type="primary" onClick={refreshPage}>
          Refresh
        </Button>
      }
    />
  );
}
