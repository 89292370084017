import { Empty, Table } from "antd";
import moment from "moment";
import React from "react";
import { findInArr } from "utils/common";
import { formatDateDetail } from "utils/date";
import { actionConst } from "_constants";
import { listStatusBMI } from "_constants/BMI";
import { listStatusBook } from "_constants/book";
import { listStatusCategory } from "_constants/category";
import { listCommunityShare } from "_constants/communityShare";
import { listStatusGames, listStatusQuestionGames } from "_constants/games";
import { listStatusGuestVideo } from "_constants/GuestManager/GuestVideo";
import { listStatusMusic } from "_constants/personalAwakening";
import {
  listStatusPost,
  listStatusComment,
  listStatusOpen,
} from "_constants/post";
import { listStatusProduct } from "_constants/product";
import { listStatusSpecialist } from "_constants/specialist";
import { listStatusTalkShow } from "_constants/talkshow";
import { listStatusVoucher, listTypePay } from "_constants/voucher";

import CommonCard from "./CardTable";

const checkStatus = key => {
  const status = {
    post: listStatusPost,
    category: listStatusCategory,
    book: listStatusBook,
    product: listStatusProduct,
    BMI: listStatusBMI,
    specialist: listStatusSpecialist,
    communityShare: listCommunityShare,
    talkshow: listStatusTalkShow,
    voucher: listStatusVoucher,
    meditationMusic: listStatusMusic,
    meditationVideo: listStatusMusic,
    games: listStatusGames,
    questionGames: listStatusQuestionGames,
    guestVideo: listStatusGuestVideo,
  };
  return status[key];
};
const listKeyRedirect = {
  audio: {
    key: "audio",
    text: "File âm thanh",
  },
  cover_image: {
    key: "cover_image",
    text: "Xem hình ảnh",
  },
  image_des: {
    key: "image_des",
    text: "Xem hình ảnh",
  },
  video: {
    key: "video",
    text: "File video",
  },
};
const checkMoment = key => {
  const arr = ["end_at", "start_at", "time"];
  return arr.find(val => val === key);
};
export default function ActionLogs({
  data,
  type,
  handleChangePage,
  loading,
  filter,
}) {
  const getValueWithTypeNumber = (col, value) => {
    const objOption = {
      status: checkStatus(type),
      comment_status: listStatusComment,
      is_require_unlock: listStatusOpen,
      pay_type: listTypePay,
    };
    return findInArr(objOption[col], value);
  };
  const renderValueContent = (value, item) => {
    if (typeof value === "string") {
      if (item.column === "birthday") {
        return moment(value).format("DD/MM/YYYY");
      }
      if (checkMoment(item.column)) {
        return formatDateDetail(value);
      }
      return (
        <div
          style={{ display: "inline" }}
          dangerouslySetInnerHTML={{ __html: value }}
        />
      );
    }
    if (Array.isArray(value)) {
      return value?.length;
    }
    if (typeof value === "object") {
      if (value?.id) {
        return value?.name;
      }
      if (item.column === "cover_image") {
        return value?.name;
      }
    }
    if (typeof value === "number") {
      if (item.column === "times") {
        return moment.utc(value * 1000).format("mm:ss");
      }
      const result = getValueWithTypeNumber(item.column, value);
      return result?.name || result?.label || value;
    }
    return "";
  };
  const renderImage = idImage => {
    return `${process.env.REACT_APP_MEDIA_READ_FILE}/${idImage}`;
  };
  const renderValue = item => {
    let action = "";
    const newData = item.new_data || item.data_new;
    const oldData = item.old_data || item.data_old;
    const arrKey = Object.keys(listKeyRedirect) ?? [];
    let view = {
      oldViews: renderValueContent(oldData, item),
      newValue: renderValueContent(newData, item),
    };
    if (oldData) {
      if (newData) {
        action = "Cập nhật";
        if (arrKey.includes(item.column)) {
          return (
            <span>
              {action} <b>{renderActionContent(item)}</b>:{" "}
              <a
                rel="noreferrer"
                href={renderImage(newData?.aws_name)}
                style={{ color: "#0d6efd" }}
                target="_blank"
              >
                {listKeyRedirect[item.column].text}
              </a>
            </span>
          );
        }
        if (item.column === "content" || item.column === "pdf") {
          return (
            <span>
              {action} <b>{renderActionContent(item)}</b>
            </span>
          );
        }
        return (
          <span>
            {action} <b>{renderActionContent(item)}</b>: {view.oldViews}{" "}
            <b>{"=>"}</b> {view.newValue}
          </span>
        );
      }
      action = "Xóa";
      if (arrKey.includes(item?.column)) {
        return (
          <span>
            {action} <b>{renderActionContent(item)}</b>:{" "}
            <a
              rel="noreferrer"
              href={renderImage(view.oldViews)}
              style={{ color: "#0d6efd" }}
              target="_blank"
            >
              Xem hình ảnh
            </a>
          </span>
        );
      }
      if (item.column === "content" || item.column === "pdf") {
        return (
          <span>
            {action} <b>{renderActionContent(item)}</b>
          </span>
        );
      }
      return (
        <span>
          {action} <b>{renderActionContent(item)}</b>
        </span>
      );
    } else {
      if (item.column === "login" || item.column === "log_out") {
        return (
          <span>
            <b>{renderActionContent(item)}</b>: {view.newValue}
          </span>
        );
      } else if (newData) {
        action = "Thêm mới";
        if (arrKey.includes(item?.column)) {
          return (
            <span>
              {action} <b>{renderActionContent(item)}</b>:{" "}
              <a
                rel="noreferrer"
                href={renderImage(view.newValue)}
                style={{ color: "#0d6efd" }}
                target="_blank"
              >
                Xem hình ảnh
              </a>
            </span>
          );
        }
        if (item.column === "content" || item.column === "pdf") {
          return (
            <span>
              {action} <b>{renderActionContent(item)}</b>
            </span>
          );
        }
        return (
          <span>
            {action} <b>{renderActionContent(item)}</b>: {view.newValue}
          </span>
        );
      } else {
        return (
          <span>
            Thay đổi <b>{renderActionContent(item)}</b>
          </span>
        );
      }
    }
  };
  const renderActionContent = item => {
    return actionConst?.[type]?.[item.column] || item.column;
  };
  const columns = [
    {
      title: "Người cập nhật",
      dataIndex: ["creator_info", "name"],
      key: "creator_info",
      width: 150,
      render: value => value || "-",
    },
    {
      title: "Thao tác",
      key: "content",
      render: record => {
        return renderValue(record);
      },
    },
    {
      title: "Thời gian cập nhật",
      align: "center",
      width: 180,
      dataIndex: "created_at",
      key: "created_at",
      render: value => moment(value).format("DD/MM/YYYY HH:mm"),
    },
  ];
  return (
    <CommonCard title={`Lịch sử chỉnh sửa (${data?.total ?? 0})`}>
      <Table
        loading={loading}
        dataSource={data?.data || []}
        columns={columns}
        rowKey={"id"}
        onChange={handleChangePage}
        pagination={{
          total: data?.total,
          current: filter?.page,
          pageSize: 5,
          position: ["bottomRight"],
          showTotal: (total, range) =>
            `Tổng ${total} | Từ ${range[0]} đến ${range[1]}`,
          showSizeChanger: false,
        }}
        locale={{
          emptyText: (
            <Empty
              description="Không có dữ liệu"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          ),
        }}
      />
    </CommonCard>
  );
}
