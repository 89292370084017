import Icon from "@ant-design/icons";
import { LIST_PERMISSION } from "_constants/Permission/permission";
import { typeCategoryPost } from "_constants/categoryPost";
import { typeSpecialist } from "_constants/specialist";
import { typeTalkShow } from "_constants/talkshow";
import { heal } from "layouts/Icon";
import {
  HealBHMPostCreate,
  HealBHMPostDetail,
  HealBHMPostList,
  HealBHMTalkshowCreate,
  HealBHMTalkshowDetail,
  HealBHMTalkshowList,
  SpecialistCreate,
  SpecialistDetail,
  SpecialistList,
  TuturialPostCreate,
  TuturialPostDetail,
  TuturialPostList,
} from "./lazy-import";

const MEDITATION_HEAL = [
  {
    key: "/heal-body-heart-mind",
    title: "Chữa lành Thân - Tâm - Trí",
    label: "Chữa lành Thân - Tâm - Trí",
    icon: <Icon component={heal} />,
    display: 1,
    children: [
      {
        key: "/coffee-meditation-expert-specilist",
        title: "Thiền cà phê",
        label: "Thiền cà phê",
        display: 1,
        children: [
          {
            key: "/coffee-meditation-expert",
            title: "Chuyên gia",
            label: "Chuyên gia",
            component: props => (
              <SpecialistList
                {...props}
                permission={{
                  add: LIST_PERMISSION.HEAL_MEDITATION_EXPERT_ADD,
                  export: LIST_PERMISSION.HEAL_MEDITATION_EXPERT_EXPORT,
                }}
                code={typeSpecialist["coffee-meditation-expert"].code}
              />
            ),
            action_key: LIST_PERMISSION.HEAL_MEDITATION_EXPERT_VIEW,
            display: 1,
          },
          {
            key: "/coffee-meditation-expert/:id",
            title: "Chi tiết chuyên gia",
            label: "Chi tiết chuyên gia",
            component: props => (
              <SpecialistDetail
                {...props}
                permission={{
                  update: LIST_PERMISSION.HEAL_MEDITATION_EXPERT_UPDATE,
                }}
                code={typeSpecialist["coffee-meditation-expert"].code}
              />
            ),
            action_key: LIST_PERMISSION.HEAL_MEDITATION_EXPERT_VIEW,
          },
          {
            key: "/coffee-meditation-expert/add",
            title: "Thêm chuyên gia về Thiền cà phê",
            label: "Thêm chuyên gia về Thiền cà phê",
            component: props => (
              <SpecialistCreate
                {...props}
                code={typeSpecialist["coffee-meditation-expert"].code}
              />
            ),
            action_key: LIST_PERMISSION.HEAL_MEDITATION_EXPERT_ADD,
          },
          {
            key: "/coffee-meditation-post",
            title: "Bài viết",
            label: "Bài viết",
            component: props => (
              <HealBHMPostList
                {...props}
                permission={{
                  add: LIST_PERMISSION.HEAL_MEDITATION_POST_ADD,
                  export: LIST_PERMISSION.HEAL_MEDITATION_POST_EXPORT,
                }}
                code={typeCategoryPost.NC_SUB_CHUYEN_GIA_THIEN_CA_PHE.value}
                codeExpert={typeSpecialist["coffee-meditation-expert"].code}
              />
            ),
            display: 1,
            action_key: LIST_PERMISSION.HEAL_MEDITATION_POST_VIEW,
          },
          {
            key: "/coffee-meditation-post/add",
            title: "Thêm bài viết",
            label: "Thêm bài viết",
            component: props => (
              <HealBHMPostCreate
                {...props}
                code={typeCategoryPost.NC_SUB_CHUYEN_GIA_THIEN_CA_PHE.value}
                codeExpert={typeSpecialist["coffee-meditation-expert"].code}
              />
            ),
            action_key: LIST_PERMISSION.HEAL_MEDITATION_POST_ADD,
          },
          {
            key: "/coffee-meditation-post/:id",
            title: "Chi tiết bài viết",
            label: "Chi tiết bài viết",
            component: props => (
              <HealBHMPostDetail
                {...props}
                permission={{
                  update: LIST_PERMISSION.HEAL_MEDITATION_POST_UPDATE,
                }}
                code={typeCategoryPost.NC_SUB_CHUYEN_GIA_THIEN_CA_PHE.value}
                codeExpert={typeSpecialist["coffee-meditation-expert"].code}
              />
            ),
            action_key: LIST_PERMISSION.HEAL_MEDITATION_POST_VIEW,
          },
          {
            key: "/coffee-meditation-talkshow",
            title: "Talkshow",
            label: "Talkshow",
            component: props => (
              <HealBHMTalkshowList
                {...props}
                permission={{
                  add: LIST_PERMISSION.HEAL_MEDITATION_TALKSHOW_ADD,
                  export: LIST_PERMISSION.HEAL_MEDITATION_TALKSHOW_EXPORT,
                }}
                code={typeTalkShow.EC_SUB_CHUYEN_GIA_THIEN_CA_PHE.value}
                codeExpert={typeSpecialist["coffee-meditation-expert"].code}
              />
            ),
            action_key: LIST_PERMISSION.HEAL_MEDITATION_TALKSHOW_VIEW,
            display: 1,
          },
          {
            key: "/coffee-meditation-talkshow/add",
            title: "Thêm Talkshow",
            label: "Thêm Talkshow",
            component: props => (
              <HealBHMTalkshowCreate
                {...props}
                code={typeTalkShow.EC_SUB_CHUYEN_GIA_THIEN_CA_PHE.value}
                codeExpert={typeSpecialist["coffee-meditation-expert"].code}
              />
            ),
            action_key: LIST_PERMISSION.HEAL_MEDITATION_POST_ADD,
          },
          {
            key: "/coffee-meditation-talkshow/:id",
            title: "Chi tiết Talkshow",
            label: "Chi tiết Talkshow",
            component: props => (
              <HealBHMTalkshowDetail
                {...props}
                permission={{
                  update: LIST_PERMISSION.HEAL_MEDITATION_TALKSHOW_UPDATE,
                  removeComment:
                    LIST_PERMISSION.HEAL_MEDITATION_TALKSHOW_REMOVE_COMMENT,
                  approveQuestion:
                    LIST_PERMISSION.HEAL_MEDITATION_TALKSHOW_APPROVE_QUESTION,
                  controlLivetream:
                    LIST_PERMISSION.HEAL_MEDITATION_TALKSHOW_OPEN_CLOSE,
                  controlTalkshow:
                    LIST_PERMISSION.HEAL_MEDITATION_TALKSHOW_APPROVE_CANCEL,
                  controlComment:
                    LIST_PERMISSION.HEAL_MEDITATION_TALKSHOW_OPEN_CLOSE_COMMENT,
                }}
                code={typeTalkShow.EC_SUB_CHUYEN_GIA_THIEN_CA_PHE.value}
                codeExpert={typeSpecialist["coffee-meditation-expert"].code}
              />
            ),
            action_key: LIST_PERMISSION.HEAL_MEDITATION_TALKSHOW_VIEW,
          },
        ],
      },
      {
        key: "/contemporary-meditation-expert-specilist",
        title: "Thiền Động",
        label: "Thiền Động",
        display: 1,
        children: [
          {
            key: "/contemporary-meditation-expert",
            title: "Chuyên gia",
            label: "Chuyên gia",
            component: props => (
              <SpecialistList
                {...props}
                permission={{
                  add: LIST_PERMISSION.HEAL_MOVING_MEDITATION_EXPERT_ADD,
                  export: LIST_PERMISSION.HEAL_MOVING_MEDITATION_EXPERT_EXPORT,
                }}
                code={typeSpecialist["contemporary-meditation-expert"].code}
              />
            ),
            action_key: LIST_PERMISSION.HEAL_MOVING_MEDITATION_EXPERT_VIEW,
            display: 1,
          },
          {
            key: "/contemporary-meditation-expert/:id",
            title: "Chi tiết chuyên gia về Thiền động",
            label: "Chi tiết chuyên gia về Thiền động",
            component: props => (
              <SpecialistDetail
                {...props}
                permission={{
                  update: LIST_PERMISSION.HEAL_MOVING_MEDITATION_EXPERT_UPDATE,
                }}
                code={typeSpecialist["contemporary-meditation-expert"].code}
              />
            ),
            action_key: LIST_PERMISSION.HEAL_MOVING_MEDITATION_EXPERT_VIEW,
          },
          {
            key: "/contemporary-meditation-expert/add",
            title: "Thêm chuyên gia về  Thiền động",
            label: "Thêm chuyên gia về  Thiền động",
            component: props => (
              <SpecialistCreate
                {...props}
                code={typeSpecialist["contemporary-meditation-expert"].code}
              />
            ),
            action_key: LIST_PERMISSION.HEAL_MOVING_MEDITATION_EXPERT_ADD,
          },
          {
            key: "/contemporary-meditation-post",
            title: "Bài viết",
            label: "Bài viết",
            component: props => (
              <HealBHMPostList
                {...props}
                permission={{
                  add: LIST_PERMISSION.HEAL_MOVING_MEDITATION_POST_ADD,
                  export: LIST_PERMISSION.HEAL_MOVING_MEDITATION_POST_EXPORT,
                }}
                code={typeCategoryPost.NC_SUB_CHUYEN_GIA_THIEN_DUONG_DAI.value}
                codeExpert={
                  typeSpecialist["contemporary-meditation-expert"].code
                }
              />
            ),
            display: 1,
            action_key: LIST_PERMISSION.HEAL_MOVING_MEDITATION_POST_VIEW,
          },
          {
            key: "/contemporary-meditation-post/add",
            title: "Thêm bài viết",
            label: "Thêm bài viết",
            component: props => (
              <HealBHMPostCreate
                {...props}
                code={typeCategoryPost.NC_SUB_CHUYEN_GIA_THIEN_DUONG_DAI.value}
                codeExpert={
                  typeSpecialist["contemporary-meditation-expert"].code
                }
              />
            ),
            action_key: LIST_PERMISSION.HEAL_MOVING_MEDITATION_POST_ADD,
          },
          {
            key: "/contemporary-meditation-post/:id",
            title: "Chi tiết bài viết",
            label: "Chi tiết bài viết",
            component: props => (
              <HealBHMPostDetail
                {...props}
                permission={{
                  update: LIST_PERMISSION.HEAL_MOVING_MEDITATION_POST_UPDATE,
                }}
                code={typeCategoryPost.NC_SUB_CHUYEN_GIA_THIEN_DUONG_DAI.value}
                codeExpert={
                  typeSpecialist["contemporary-meditation-expert"].code
                }
              />
            ),
            action_key: LIST_PERMISSION.HEAL_MOVING_MEDITATION_POST_VIEW,
          },
          {
            key: "/contemporary-meditation-talkshow",
            title: "Talkshow",
            label: "Talkshow",
            component: props => (
              <HealBHMTalkshowList
                {...props}
                permission={{
                  add: LIST_PERMISSION.HEAL_MOVING_MEDITATION_TALKSHOW_ADD,
                  export:
                    LIST_PERMISSION.HEAL_MOVING_MEDITATION_TALKSHOW_EXPORT,
                }}
                code={typeTalkShow.EC_SUB_CHUYEN_GIA_THIEN_DUONG_DAI.value}
                codeExpert={
                  typeSpecialist["contemporary-meditation-expert"].code
                }
              />
            ),
            action_key: LIST_PERMISSION.HEAL_MOVING_MEDITATION_TALKSHOW_VIEW,
            display: 1,
          },
          {
            key: "/contemporary-meditation-talkshow/add",
            title: "Thêm Talkshow",
            label: "Thêm Talkshow",
            component: props => (
              <HealBHMTalkshowCreate
                {...props}
                code={typeTalkShow.EC_SUB_CHUYEN_GIA_THIEN_DUONG_DAI.value}
                codeExpert={
                  typeSpecialist["contemporary-meditation-expert"].code
                }
              />
            ),
            action_key: LIST_PERMISSION.HEAL_MOVING_MEDITATION_TALKSHOW_ADD,
          },
          {
            key: "/contemporary-meditation-talkshow/:id",
            title: "Chi tiết Talkshow",
            label: "Chi tiết Talkshow",
            component: props => (
              <HealBHMTalkshowDetail
                {...props}
                permission={{
                  update:
                    LIST_PERMISSION.HEAL_MOVING_MEDITATION_TALKSHOW_UPDATE,
                  removeComment:
                    LIST_PERMISSION.HEAL_MOVING_MEDITATION_TALKSHOW_REMOVE_COMMENT,
                  approveQuestion:
                    LIST_PERMISSION.HEAL_MOVING_MEDITATION_TALKSHOW_APPROVE_QUESTION,
                  controlLivetream:
                    LIST_PERMISSION.HEAL_MOVING_MEDITATION_TALKSHOW_OPEN_CLOSE,
                  controlTalkshow:
                    LIST_PERMISSION.HEAL_MOVING_MEDITATION_TALKSHOW_APPROVE_CANCEL,
                  controlComment:
                    LIST_PERMISSION.HEAL_MEDITATION_TALKSHOW_OPEN_CLOSE_COMMENT,
                }}
                code={typeTalkShow.EC_SUB_CHUYEN_GIA_THIEN_DUONG_DAI.value}
                codeExpert={
                  typeSpecialist["contemporary-meditation-expert"].code
                }
              />
            ),
            action_key: LIST_PERMISSION.HEAL_MOVING_MEDITATION_TALKSHOW_VIEW,
          },
        ],
      },
      {
        key: "/yoga-expert-specilist",
        title: "Yoga",
        label: "Yoga",
        action_key: LIST_PERMISSION,
        display: 1,
        children: [
          {
            key: "/yoga-expert",
            title: "Chuyên gia",
            label: "Chuyên gia",
            component: props => (
              <SpecialistList
                {...props}
                permission={{
                  add: LIST_PERMISSION.HEAL_YOGA_EXPERT_ADD,
                  export: LIST_PERMISSION.HEAL_YOGA_EXPERT_EXPORT,
                }}
                code={typeSpecialist["yoga-expert"].code}
              />
            ),
            action_key: LIST_PERMISSION.HEAL_YOGA_EXPERT_VIEW,
            display: 1,
          },
          {
            key: "/yoga-expert/:id",
            title: "Chi tiết chuyên gia",
            label: "Chi tiết chuyên gia",
            component: props => (
              <SpecialistDetail
                {...props}
                permission={{
                  update: LIST_PERMISSION.HEAL_YOGA_EXPERT_UPDATE,
                }}
                code={typeSpecialist["yoga-expert"].code}
              />
            ),
            action_key: LIST_PERMISSION.HEAL_YOGA_EXPERT_VIEW,
          },
          {
            key: "/yoga-expert/add",
            title: "Thêm chuyên gia về  Yoga",
            label: "Thêm chuyên gia về  Yoga",
            component: props => (
              <SpecialistCreate
                {...props}
                code={typeSpecialist["yoga-expert"].code}
              />
            ),
            action_key: LIST_PERMISSION.HEAL_YOGA_EXPERT_ADD,
          },
          {
            key: "/yoga-post",
            title: "Bài viết",
            label: "Bài viết",
            component: props => (
              <HealBHMPostList
                {...props}
                permission={{
                  add: LIST_PERMISSION.HEAL_GUIDE_CONNECT_POST_ADD,
                  export: LIST_PERMISSION.HEAL_GUIDE_CONNECT_POST_ADD,
                }}
                code={typeCategoryPost.NC_SUB_CHUYEN_GIA_YOGA.value}
                codeExpert={typeSpecialist["yoga-expert"].code}
              />
            ),
            display: 1,
            action_key: LIST_PERMISSION.HEAL_YOGA_POST_VIEW,
          },
          {
            key: "/yoga-post/add",
            title: "Thêm bài viết",
            label: "Thêm bài viết",
            component: props => (
              <HealBHMPostCreate
                {...props}
                code={typeCategoryPost.NC_SUB_CHUYEN_GIA_YOGA.value}
                codeExpert={typeSpecialist["yoga-expert"].code}
              />
            ),
            action_key: LIST_PERMISSION.HEAL_YOGA_POST_ADD,
          },
          {
            key: "/yoga-post/:id",
            title: "Chi tiết bài viết",
            label: "Chi tiết bài viết",
            component: props => (
              <HealBHMPostDetail
                {...props}
                permission={{
                  update: LIST_PERMISSION.HEAL_YOGA_POST_UPDATE,
                }}
                code={typeCategoryPost.NC_SUB_CHUYEN_GIA_YOGA.value}
                codeExpert={typeSpecialist["yoga-expert"].code}
              />
            ),
            action_key: LIST_PERMISSION.HEAL_YOGA_POST_VIEW,
          },
          {
            key: "/yoga-talkshow",
            title: "Talkshow",
            label: "Talkshow",
            component: props => (
              <HealBHMTalkshowList
                {...props}
                permission={{
                  add: LIST_PERMISSION.HEAL_YOGA_TALKSHOW_ADD,
                  export: LIST_PERMISSION.HEAL_YOGA_TALKSHOW_EXPORT,
                }}
                code={typeTalkShow.EC_SUB_CHUYEN_GIA_YOGA.value}
                codeExpert={typeSpecialist["yoga-expert"].code}
              />
            ),
            action_key: LIST_PERMISSION.HEAL_YOGA_TALKSHOW_VIEW,
            display: 1,
          },
          {
            key: "/yoga-talkshow/add",
            title: "Thêm Talkshow",
            label: "Thêm Talkshow",
            component: props => (
              <HealBHMTalkshowCreate
                {...props}
                code={typeTalkShow.EC_SUB_CHUYEN_GIA_YOGA.value}
                codeExpert={typeSpecialist["yoga-expert"].code}
              />
            ),
            action_key: LIST_PERMISSION.HEAL_YOGA_POST_ADD,
          },
          {
            key: "/yoga-talkshow/:id",
            title: "Chi tiết Talkshow",
            label: "Chi tiết Talkshow",
            component: props => (
              <HealBHMTalkshowDetail
                {...props}
                permission={{
                  update: LIST_PERMISSION.HEAL_YOGA_TALKSHOW_UPDATE,
                  removeComment:
                    LIST_PERMISSION.HEAL_YOGA_TALKSHOW_REMOVE_COMMENT,
                  approveQuestion:
                    LIST_PERMISSION.HEAL_YOGA_TALKSHOW_APPROVE_QUESTION,
                  controlLivetream:
                    LIST_PERMISSION.HEAL_YOGA_TALKSHOW_OPEN_CLOSE,
                  controlTalkshow:
                    LIST_PERMISSION.HEAL_YOGA_TALKSHOW_APPROVE_CANCEL,
                  controlComment:
                    LIST_PERMISSION.HEAL_YOGA_TALKSHOW_OPEN_CLOSE_COMMENT,
                }}
                code={typeTalkShow.EC_SUB_CHUYEN_GIA_YOGA.value}
                codeExpert={typeSpecialist["yoga-expert"].code}
              />
            ),
            action_key: LIST_PERMISSION.HEAL_YOGA_TALKSHOW_VIEW,
          },
        ],
      },
      {
        key: "/therapist-specilist-menu",
        title: "Trị liệu",
        label: "Trị liệu",
        action_key: LIST_PERMISSION,
        display: 1,
        children: [
          {
            key: "/therapist-specilist",
            title: "Chuyên gia",
            label: "Chuyên gia",
            component: props => (
              <SpecialistList
                {...props}
                permission={{
                  add: LIST_PERMISSION.HEAL_THERAPY_EXPERT_ADD,
                  export: LIST_PERMISSION.HEAL_THERAPY_EXPERT_EXPORT,
                }}
                code={typeSpecialist["therapist"].code}
              />
            ),
            action_key: LIST_PERMISSION.HEAL_THERAPY_EXPERT_VIEW,
            display: 1,
          },
          {
            key: "/therapist-specilist/:id",
            title: "Chi tiết chuyên gia",
            label: "Chi tiết chuyên gia",
            component: props => (
              <SpecialistDetail
                {...props}
                permission={{
                  update: LIST_PERMISSION.HEAL_YOGA_EXPERT_UPDATE,
                }}
                code={typeSpecialist["therapist"].code}
              />
            ),
            action_key: LIST_PERMISSION.HEAL_THERAPY_EXPERT_VIEW,
          },
          {
            key: "/therapist-specilist/add",
            title: "Thêm chuyên gia",
            label: "Thêm chuyên gia",
            component: props => (
              <SpecialistCreate
                {...props}
                code={typeSpecialist["therapist"].code}
              />
            ),
            action_key: LIST_PERMISSION.HEAL_THERAPY_EXPERT_ADD,
          },
          {
            key: "/therapist-post",
            title: "Bài viết",
            label: "Bài viết",
            component: props => (
              <HealBHMPostList
                {...props}
                permission={{
                  add: LIST_PERMISSION.HEAL_THERAPY_POST_ADD,
                  export: LIST_PERMISSION.HEAL_THERAPY_POST_EXPORT,
                }}
                code={typeCategoryPost.NC_SUB_CHUYEN_GIA_TRI_LIEU.value}
                codeExpert={typeSpecialist["therapist"].code}
              />
            ),
            display: 1,
            action_key: LIST_PERMISSION.HEAL_THERAPY_POST_VIEW,
          },
          {
            key: "/therapist-post/add",
            title: "Thêm bài viết",
            label: "Thêm bài viết",
            component: props => (
              <HealBHMPostCreate
                {...props}
                code={typeCategoryPost.NC_SUB_CHUYEN_GIA_TRI_LIEU.value}
                codeExpert={typeSpecialist["therapist"].code}
              />
            ),
            action_key: LIST_PERMISSION.HEAL_THERAPY_POST_ADD,
          },
          {
            key: "/therapist-post/:id",
            title: "Chi tiết bài viết",
            label: "Chi tiết bài viết",
            component: props => (
              <HealBHMPostDetail
                {...props}
                permission={{
                  update: LIST_PERMISSION.HEAL_THERAPY_POST_UPDATE,
                }}
                code={typeCategoryPost.NC_SUB_CHUYEN_GIA_TRI_LIEU.value}
                codeExpert={typeSpecialist["therapist"].code}
              />
            ),
            action_key: LIST_PERMISSION.HEAL_THERAPY_POST_VIEW,
          },
          {
            key: "/therapist-talkshow",
            title: "Talkshow",
            label: "Talkshow",
            component: props => (
              <HealBHMTalkshowList
                {...props}
                permission={{
                  add: LIST_PERMISSION.HEAL_THERAPY_TALKSHOW_ADD,
                  export: LIST_PERMISSION.HEAL_THERAPY_TALKSHOW_EXPORT,
                }}
                code={typeTalkShow.EC_SUB_CHUYEN_GIA_TRI_LIEU.value}
                codeExpert={typeSpecialist["therapist"].code}
              />
            ),
            action_key: LIST_PERMISSION.HEAL_THERAPY_TALKSHOW_VIEW,
            display: 1,
          },
          {
            key: "/therapist-talkshow/add",
            title: "Thêm Talkshow",
            label: "Thêm Talkshow",
            component: props => (
              <HealBHMTalkshowCreate
                {...props}
                code={typeTalkShow.EC_SUB_CHUYEN_GIA_TRI_LIEU.value}
                codeExpert={typeSpecialist["therapist"].code}
              />
            ),
            action_key: LIST_PERMISSION.HEAL_THERAPY_TALKSHOW_ADD,
          },
          {
            key: "/therapist-talkshow/:id",
            title: "Chi tiet Talkshow",
            label: "Chi tiet Talkshow",
            component: props => (
              <HealBHMTalkshowDetail
                {...props}
                permission={{
                  update: LIST_PERMISSION.HEAL_THERAPY_TALKSHOW_UPDATE,
                  removeComment:
                    LIST_PERMISSION.HEAL_THERAPY_TALKSHOW_REMOVE_COMMENT,
                  approveQuestion:
                    LIST_PERMISSION.HEAL_THERAPY_TALKSHOW_APPROVE_QUESTION,
                  controlLivetream:
                    LIST_PERMISSION.HEAL_THERAPY_TALKSHOW_OPEN_CLOSE,
                  controlTalkshow:
                    LIST_PERMISSION.HEAL_THERAPY_TALKSHOW_APPROVE_CANCEL,
                  controlComment:
                    LIST_PERMISSION.HEAL_THERAPY_TALKSHOW_OPEN_CLOSE_COMMENT,
                }}
                code={typeTalkShow.EC_SUB_CHUYEN_GIA_TRI_LIEU.value}
                codeExpert={typeSpecialist["therapist"].code}
              />
            ),
            action_key: LIST_PERMISSION.HEAL_THERAPY_TALKSHOW_VIEW,
          },
        ],
      },
      {
        key: "/tuturial-connect-post",
        title: "Hướng dẫn kết nối cộng đồng tỉnh thức",
        label: "Hướng dẫn kết nối cộng đồng tỉnh thức",
        action_key: LIST_PERMISSION.HEAL_GUIDE_CONNECT_POST_VIEW,
        component: props => <TuturialPostList {...props} />,
        display: 1,
      },
      {
        key: "/tuturial-connect-post/:id",
        title: "Hướng dẫn kết nối cộng đồng tỉnh thức",
        label: "Hướng dẫn kết nối cộng đồng tỉnh thức",
        action_key: LIST_PERMISSION.HEAL_GUIDE_CONNECT_POST_VIEW,
        component: props => <TuturialPostDetail {...props} />,
      },
      {
        key: "/tuturial-connect-post/add",
        title: "Hướng dẫn kết nối cộng đồng tỉnh thức",
        label: "Hướng dẫn kết nối cộng đồng tỉnh thức",
        action_key: LIST_PERMISSION.HEAL_GUIDE_CONNECT_POST_ADD,
        component: props => <TuturialPostCreate {...props} />,
      },
    ],
  },
];

export default MEDITATION_HEAL;
