import _ from "lodash";
import CommonCard from "shared_components/CardTable";
import RichTextEditor from "shared_components/Editor/RichTextEditor";

export default function PostContent({
  form,
  editorContent,
  inputName,
  editorName,
  isReset,
  isDisabled,
  changedContent,
}) {
  const updateContent = (content, inputKey) => {
    let formatData = _.isArray(inputKey)
      ? _.reduceRight(
          inputKey,
          (result, item) => {
            Object.keys(result).length === 0
              ? (result = { [item]: content })
              : (result = { [item]: { ...result } });
            return result;
          },
          {}
        )
      : { [inputKey]: content };

    form?.setFieldsValue({
      ...formatData,
    });
    form?.validateFields([`${inputKey}`]);
    changedContent(formatData);
  };
  return (
    <CommonCard className="primary-wrapper mb-3" title={"Nội dung bài viết"}>
      <RichTextEditor
        name={editorName}
        inputName={inputName}
        disabled={isDisabled}
        toolbarClass="mt-2 pt-3 border-top"
        data={editorContent || ""}
        updateContent={updateContent}
        saveOnChange
        isReset={isReset}
        height={400}
      />
    </CommonCard>
  );
}
