const destroy = {
  id: -3,
  key: "-3",
  name: "Hủy",
  color: "purple",
};
const active = {
  id: 2,
  key: "2",
  name: "Đã kiểm duyệt",
  color: "green",
};
const unactive = {
  id: -2,
  key: "-2",
  name: "Từ chối kiểm duyệt",
  color: "red",
};
const statusTalkShow = {
  waiting: {
    id: 1,
    key: "1",
    name: "Chờ kiểm duyệt",
    color: "blue",
    action_key: [
      { id: 1, key: "1", name: "Chờ kiểm duyệt", color: "blue" },
      active,
      unactive,
      destroy,
    ],
  },
  active: {
    ...active,
    action_key: [active, unactive, destroy],
  },
  unactive: {
    ...unactive,
    action_key: [unactive, active, destroy],
  },
  happening: {
    id: 3,
    key: "3",
    name: "Đang diễn ra",
    color: "cyan",
    disabled: true,
    action_key: [
      {
        id: 3,
        key: "3",
        name: "Đang diễn ra",
        color: "cyan",
      },
      destroy,
    ],
  },
  stopped: {
    id: 4,
    key: "4",
    name: "Đã kết thúc",
    color: "magenta",
    disabled: true,
    action_key: [
      {
        id: 4,
        key: "4",
        name: "Đã kết thúc",
        color: "magenta",
      },
      destroy,
    ],
  },
  destroy: {
    ...destroy,
    action_key: [destroy],
  },
};
const listStatusTalkShow = Object.values(statusTalkShow);
const findStatusTalkShow = (id, field) => {
  const result = listStatusTalkShow.find(e => e?.id === id * 1);
  return result?.[field] || result;
};

const formTalkshow = {
  Offline: {
    id: 1,
    key: "1",
    name: "Offline",
    color: "volcano",
  },
  online: {
    id: 2,
    key: "2",
    name: "Online",
    color: "green",
  },
};
const listFormTalkShow = Object.values(formTalkshow);
const findFormTalkShow = (id, field) => {
  const result = listFormTalkShow.find(e => e?.id === id * 1);
  return result?.[field] || result;
};
const typeTalkShow = {
  EC_SUB_CHUYEN_GIA_THIEN_CA_PHE: {
    value: "EC_SUB_CHUYEN_GIA_THIEN_CA_PHE",
    des: "Chuyên gia về thiền cà phê",
    parent_code: "EC_CHUA_LANH",
  },
  EC_SUB_CHUYEN_GIA_THIEN_DUONG_DAI: {
    value: "EC_SUB_CHUYEN_GIA_THIEN_DUONG_DAI",
    des: "Chuyên gia về thiền đương đại",
    parent_code: "EC_CHUA_LANH",
  },
  EC_SUB_CHUYEN_GIA_YOGA: {
    value: "EC_SUB_CHUYEN_GIA_YOGA",
    des: "Chuyên gia về Yoga",
    parent_code: "EC_CHUA_LANH",
  },
  EC_SUB_CHUYEN_GIA_TRI_LIEU: {
    value: "EC_SUB_CHUYEN_GIA_TRI_LIEU",
    des: "Chuyên gia Trị liệu",
    parent_code: "EC_CHUA_LANH",
  },
  EC_BAN_TIN: {
    value: "EC_BAN_TIN",
    des: "Bản tin thiền cà phê",
    parent_code: undefined,
  },
  EC_CONG_DONG_TINH_THUC: {
    value: "EC_CONG_DONG_TINH_THUC",
    des: "Cộng đồng tỉnh thức",
    parent_code: undefined,
  },
};
const urlTalkshow = {
  EC_SUB_CHUYEN_GIA_THIEN_CA_PHE: {
    url: "coffee-meditation-talkshow",
  },
  EC_SUB_CHUYEN_GIA_THIEN_DUONG_DAI: {
    url: "contemporary-meditation-talkshow",
  },
  EC_SUB_CHUYEN_GIA_YOGA: {
    url: "yoga-talkshow",
  },
  EC_SUB_CHUYEN_GIA_TRI_LIEU: {
    url: "therapist-talkshow",
  },
  EC_BAN_TIN: {
    url: "events-newsletter",
  },
  EC_CONG_DONG_TINH_THUC: {
    url: "community-share-event",
  },
};
const listTypeTalkShow = Object.values(typeTalkShow);

const statusComment = {
  show: {
    id: 1,
    key: "1",
    name: "Hiển thị",
    color: "orange",
  },
  lock: {
    id: -1,
    key: "-1",
    name: "Xóa",
    color: "green",
  },
};
const listStatusComment = Object.values(statusComment);
const findStatusComment = (id, field) => {
  const result = listStatusComment.find(e => e?.id * 1 === id * 1);
  return result?.[field] || result;
};

const statusQuestion = {
  unactive: {
    id: 1,
    key: "1",
    name: "Chưa duyệt",
    color: "orange",
  },
  active: {
    id: 2,
    key: "2",
    name: "Đã duyệt",
    color: "green",
  },
  reject: {
    id: -2,
    key: "-2",
    name: "Từ chối",
    color: "red",
  },
};
const listStatusQuestion = Object.values(statusQuestion);
const findStatusQuestion = (id, field) => {
  const result = listStatusQuestion.find(e => e?.id * 1 === id * 1);
  return result?.[field] || result;
};

const statusCommentLivestream = {
  show: {
    id: 1,
    key: "1",
    name: "Cho phép bình luận",
    color: "orange",
  },
  lock: {
    id: -1,
    key: "-1",
    name: "Không cho phép bình luận",
    color: "green",
  },
};
const listStatusCommentLivestream = Object.values(statusCommentLivestream);
const findStatusCommentLivestream = (id, field) => {
  const result = listStatusCommentLivestream.find(e => e?.id * 1 === id * 1);
  return result?.[field] || result;
};

const statusLivestream = {
  show: {
    id: 1,
    key: "1",
    name: "Bật Livestream",
    color: "orange",
  },
  lock: {
    id: -1,
    key: "-1",
    name: "Tắt Livestream",
    color: "green",
  },
};
const listStatusLivestream = Object.values(statusLivestream);
const findStatusLivestream = (id, field) => {
  const result = listStatusLivestream.find(e => e?.id * 1 === id * 1);
  return result?.[field] || result;
};

const requiredTalkshow = {
  required: {
    id: 1,
    key: "1",
    name: "Bắt buộc",
    color: "green",
  },
  not_required: {
    id: -1,
    key: "-1",
    name: "Không bắt buộc",
    color: "red",
  },
};

const listRequiredTalkshow = Object.values(requiredTalkshow);
const findRequiredTalkshow = (id, field) => {
  const result = listRequiredTalkshow.find(e => e?.id === id * 1);
  return result?.[field] || result;
};

export {
  listStatusTalkShow,
  statusTalkShow,
  findStatusTalkShow,
  listTypeTalkShow,
  typeTalkShow,
  urlTalkshow,
  formTalkshow,
  listFormTalkShow,
  findFormTalkShow,
  findStatusComment,
  listStatusComment,
  statusComment,
  statusQuestion,
  listStatusQuestion,
  findStatusQuestion,
  findStatusCommentLivestream,
  listStatusCommentLivestream,
  statusCommentLivestream,
  findStatusLivestream,
  statusLivestream,
  findRequiredTalkshow,
  listRequiredTalkshow,
  requiredTalkshow,
};
