import Icon from "@ant-design/icons";

import { listPathname } from "_constants/ListPathname/pathname";
import { LIST_PERMISSION } from "_constants/Permission/permission";
import { user } from "layouts/Icon";
import {
  GroupUserCreate,
  GroupUserDetail,
  GroupUserList,
  UserCreate,
  UserDetail,
  UserList,
} from "./lazy-import";

const USER_MANAGEMENT = [
  {
    key: listPathname.management_user.sub,
    title: "Quản lý người dùng",
    label: "Quản lý người dùng",
    icon: <Icon component={user} />,
    display: 1,
    children: [
      {
        key: listPathname.management_user.groups.list,
        title: "Nhóm người dùng",
        label: "Nhóm người dùng",
        component: props => <GroupUserList {...props} />,
        action_key: LIST_PERMISSION.USER_GROUP_VIEW,
        display: 1,
      },
      {
        key: listPathname.management_user.groups.details,
        title: "Chi tiết nhóm người dùng",
        label: "Chi tiết nhóm người dùng",
        component: props => <GroupUserDetail {...props} />,
        action_key: LIST_PERMISSION.USER_GROUP_VIEW,
      },
      {
        key: listPathname.management_user.groups.add,
        title: "Thêm nhóm người dùng",
        label: "Thêm nhóm người dùng",
        component: props => <GroupUserCreate {...props} />,
        action_key: LIST_PERMISSION.USER_GROUP_ADD,
      },
      {
        key: listPathname.management_user.users.list,
        title: "Danh sách người dùng",
        label: "Danh sách người dùng",
        component: props => <UserList {...props} />,
        action_key: LIST_PERMISSION.USER_VIEW,
        display: 1,
      },
      {
        key: listPathname.management_user.users.detail,
        title: "Chi tiết người dùng",
        label: "Chi tiết người dùng",
        component: props => <UserDetail {...props} />,
        action_key: LIST_PERMISSION.USER_VIEW,
      },
      {
        key: listPathname.management_user.users.add,
        title: "Thêm người dùng",
        label: "Thêm người dùng",
        component: props => <UserCreate {...props} />,
        action_key: LIST_PERMISSION.USER_ADD,
      },
    ],
  },
];
export default USER_MANAGEMENT;
