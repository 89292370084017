import React, { Component } from "react";
import classnames from "classnames";
import CKEditor from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import "./editor.css";

import CustomEmbedProviders from "./EmbedProvider";
import MyUploadAdapter from "./MyUploadAdapter";

class RichTextEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.data,
      uploadImage: null,
    };
    this.editor = null;
  }

  onChange = value => {
    if (this.props.saveOnChange) {
      this.props.updateContent(
        value.replaceAll(
          "modestbranding=1&amp;showinfo=0",
          "modestbranding=1&showinfo=0"
        ),
        this.props.inputName
      );
      this.setState({ value });
    }
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.isReset !== this.props.isReset ||
      prevProps.data !== this.props.data
    ) {
      this.editor?.setData(this.props.data);
    }
  }
  render() {
    return (
      <div
        id="editor-wrapper"
        className={classnames(
          "document-editor editor-wrapper mt-0",
          this.props.wrapperClass,
          this.props.name
        )}
        style={{ height: this.props.height }}
      >
        <div className="document-editor__toolbar"></div>
        <div className="document-editor__editable-container">
          <div
            className={classnames(
              "document-editor__editable",
              this.props.customClass
            )}
          >
            <CKEditor
              style={
                this.props.editableStyle ? { ...this.props.editableStyle } : {}
              }
              ref={this.editor}
              id="editor"
              disabled={this.props.disabled}
              editor={DecoupledEditor}
              data={this.state.value || ""}
              onInit={editor => {
                if (this.editor?.ui?.view?.toolbar?.element) {
                  this.editor.ui.view.toolbar.element.remove();
                }
                editor.plugins.get("FileRepository").createUploadAdapter =
                  function (loader) {
                    return new MyUploadAdapter(loader);
                  };
                // Insert the toolbar before the editable area.
                editor.ui
                  .getEditableElement()
                  .parentElement.insertBefore(
                    editor.ui.view.toolbar.element,
                    editor.ui.getEditableElement()
                  );

                this.editor = editor;

                let editorClass = "";
                if (this.props.name) {
                  editorClass = "." + this.props.name;
                }
                // const toolbarContainer = document.querySelector(
                //   classnames(editorClass, ".document-editor__toolbar")
                // );
                // toolbarContainer?.appendChild(editor.ui.view.toolbar.element);
                const toolbarContainerAll = document.querySelectorAll(
                  classnames(editorClass, ".document-editor__toolbar")
                );
                toolbarContainerAll.forEach(e => {
                  if (e.childNodes.length === 0) {
                    e.appendChild(editor.ui.view.toolbar.element);
                  }
                });

                // window.editor = editor;
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                this.onChange(data);
              }}
              config={{
                toolbar: this.props.toolbar
                  ? this.props.toolbar
                  : [
                      "heading",
                      "|",
                      "fontsize",
                      "|",
                      "bold",
                      "italic",
                      "underline",
                      "strikethrough",
                      "|",
                      "alignment",
                      "|",
                      "numberedList",
                      "bulletedList",
                      "|",
                      "indent",
                      "outdent",
                      "|",
                      "link",
                      "blockquote",
                      "imageUpload",
                      "insertTable",
                      "mediaEmbed",
                      "|",
                      "undo",
                      "redo",
                    ],
                keystrokes: this.props.keystrokes
                  ? this.props.keystrokes
                  : undefined,
                link: {
                  addTargetToExternalLinks: true,
                },
                image: {
                  resizeUnit: "%",
                  resizeOptions: [
                    {
                      name: "imageResize:original",
                      value: null,
                    },
                    {
                      name: "imageResize:75",
                      value: "75",
                    },
                    {
                      name: "imageResize:50",
                      value: "50",
                    },
                  ],
                  // toolbar: [ 'imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:alignRight', '|', 'imageResize', '|', 'imageTextAlternative', ],
                  toolbar: ["imageResize", "|", "imageTextAlternative"],
                },
                mediaEmbed: {
                  previewsInData: true,
                  providers: CustomEmbedProviders,
                },
                fontSize: {
                  options: [12, 14, "default", 18, 24, 28, 32],
                },
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default RichTextEditor;
