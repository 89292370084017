import Icon from "@ant-design/icons";

import { LIST_PERMISSION } from "_constants/Permission/permission";
import { survey } from "layouts/Icon";
import {
  QuestionCreate,
  QuestionDetail,
  QuestionList,
  SurveyCreate,
  SurveyDetail,
  SurveyList,
} from "./lazy-import";

const QUESTION = [
  {
    title: "Câu hỏi khảo sát",
    label: "Câu hỏi khảo sát",
    icon: <Icon component={survey} />,
    display: 1,
    children: [
      {
        key: "/surveys",
        title: "Bộ câu hỏi",
        label: "Bộ câu hỏi",
        component: props => <SurveyList {...props} />,
        action_key: LIST_PERMISSION.SURVEY_SET_OF_QUESTION_VIEW,
        display: 1,
      },
      {
        key: "/surveys/:id",
        title: "Chi tiết bộ câu hỏi",
        label: "Chi tiết bộ câu hỏi",
        component: props => <SurveyDetail {...props} />,
        action_key: LIST_PERMISSION.SURVEY_SET_OF_QUESTION_VIEW,
      },
      {
        key: "/surveys/add",
        title: "Thêm câu hỏi khảo sát",
        label: "Thêm câu hỏi khảo sát",
        component: props => <SurveyCreate {...props} />,
        action_key: LIST_PERMISSION.SURVEY_SET_OF_QUESTION_ADD,
      },
      {
        key: "/questions",
        title: "Câu hỏi",
        label: "Câu hỏi",
        component: props => <QuestionList {...props} />,
        action_key: LIST_PERMISSION.SURVEY_QUESTION_VIEW,
        display: 1,
      },
      {
        key: "/questions/:id",
        title: "Chi tiết câu hỏi",
        label: "Chi tiết câu hỏi",
        component: props => <QuestionDetail {...props} />,
        action_key: LIST_PERMISSION.SURVEY_QUESTION_VIEW,
      },
      {
        key: "/questions/add",
        title: "Thêm câu hỏi khảo sát",
        label: "Thêm câu hỏi khảo sát",
        component: props => <QuestionCreate {...props} />,
        action_key: LIST_PERMISSION.SURVEY_QUESTION_ADD,
      },
    ],
  },
];
export default QUESTION;
