import { Button, Result } from "antd";
import { Link } from "react-router-dom";

const NotPermission = () => {
  return (
    <Result
      status="403"
      title="403"
      subTitle="Xin lỗi, bạn không được phép truy cập trang này."
      extra={
        <Link to={"/"} replace>
          <Button type="primary">Về trang chủ</Button>
        </Link>
      }
    />
  );
};

export default NotPermission;
