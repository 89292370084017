import axiosClient from "apis/axiosClient";
import { METHOD } from "_constants";

const AuthAPI = {
  login(data) {
    const url = "/login";
    return axiosClient(METHOD.PPD).post(url, data);
  },

  logout() {
    const url = "/logout";
    return axiosClient(METHOD.PPD).put(url);
  },
};

export default AuthAPI;
