import { storage } from "_constants";
import { useMemo } from "react";

const storagePermission = () => localStorage[storage.PERMISSION];

const usePermission = (keyPermission = "") => {
  const permission = storagePermission() && JSON.parse(storagePermission());

  const havePermission = useMemo(
    () => !!permission?.[keyPermission],
    [permission]
  );
  return {
    listPermissions: permission,
    havePermission,
  };
};

export default usePermission;
