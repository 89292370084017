import { Breadcrumb } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const defaultRoutes = [{ path: "/", name: "Trang chủ" }];

function CustomBreadcrumb({ routes }) {
  const renderBreadcrumItems = routes => {
    const newRoutes = [...defaultRoutes, ...routes];
    return newRoutes.map((item, index) => {
      if (item.active || item.disabled) {
        document.title = item.name;
        return (
          <Breadcrumb.Item key={"item" + index}>{item.name}</Breadcrumb.Item>
        );
      }

      return (
        <Breadcrumb.Item key={"item" + index} className="cursor-pointer">
          <Link
            to={{
              pathname: item.path,
              search: item.search,
            }}
          >
            {item.name}
          </Link>
        </Breadcrumb.Item>
      );
    });
  };

  return (
    <Breadcrumb className="h6 mt-2 mb-3">
      {renderBreadcrumItems(routes)}
    </Breadcrumb>
  );
}

export default CustomBreadcrumb;
