import React from "react";

const Container = ({ children, style = {}, radius = false, ...props }) => {
  return (
    <div style={{ padding: "0 16px", ...style }} {...props}>
      <div className={radius ? "primary-radius-container" : null}>
        {children}
      </div>
    </div>
  );
};

export default Container;
