import { useNav, useNetworkStatus } from "hooks";
import { Suspense, createContext, lazy } from "react";
import { BrowserRouter } from "react-router-dom";
import { Loading } from "shared_components";
import NoInternet from "shared_components/NoInternet";
import "./App.less";
import "./styles/bootstrap.min.css";
import "./styles/custom.css";

const Routers = lazy(() => import("Routers"));
export const NavsContext = createContext();

function App() {
  const statusNetwork = useNetworkStatus();
  const navs = useNav();

  return (
    <div className="App">
      {statusNetwork ? (
        <Suspense fallback={<Loading />}>
          <NavsContext.Provider value={navs}>
            <BrowserRouter>
              <Routers />
            </BrowserRouter>
          </NavsContext.Provider>
        </Suspense>
      ) : (
        <NoInternet />
      )}
    </div>
  );
}

export default App;
