const statusMusic = {
  new: {
    id: 1,
    key: "1",
    name: "Mới tạo",
    color: "blue",
  },
  active: {
    id: 2,
    key: "2",
    name: "Đang hoạt động",
    color: "green",
  },
  unActive: {
    id: -2,
    key: "-2",
    name: "Ngưng hoạt động",
    color: "red",
  },
};
const listStatusMusic = Object.values(statusMusic);
const findStatusMusic = (id, field) => {
  const result = listStatusMusic.find(e => e.id === id * 1);
  return result?.[field] || result;
};

const typeMeditation = {
  music: {
    id: 1,
    key: "1",
    name: "Nhạc thiền",
  },
  video: {
    id: 2,
    key: "2",
    name: "Video thiền",
  },
  podcast: {
    id: 4,
    key: "4",
    name: "Sách nói",
  },
};

export { listStatusMusic, findStatusMusic, statusMusic, typeMeditation };
