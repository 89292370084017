const statusProduct = {
  active: {
    id: 1,
    key: "1",
    name: "Đang hoạt động",
    color: "green",
  },
  unactive: {
    id: -1,
    key: "-1",
    name: "Ngưng hoạt động",
    color: "red",
  },
};
const listStatusProduct = Object.values(statusProduct);
const findStatusProduct = (id, field) => {
  const result = listStatusProduct.find(e => e.id === id * 1);
  return result?.[field] || result;
};

const statusGroupProduct = {
  active: {
    id: 1,
    key: "1",
    name: "Đang hoạt động",
    color: "green",
  },
  unactive: {
    id: -1,
    key: "-1",
    name: "Ngưng hoạt động",
    color: "red",
  },
};
const listStatusGroupProduct = Object.values(statusGroupProduct);
const findStatusGroupProduct = (id, field) => {
  const result = listStatusGroupProduct.find(e => e.id === id * 1);
  return result?.[field] || result;
};

const typeLogVAT = 6;

export {
  statusProduct,
  listStatusProduct,
  findStatusProduct,
  typeLogVAT,
  statusGroupProduct,
  listStatusGroupProduct,
  findStatusGroupProduct,
};
