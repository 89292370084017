const codeCategoryBook = {
  BC_SUB_SACH_DOI_DOI: {
    value: "BC_SUB_SACH_DOI_DOI",
    des: "5 đầu sách đổi đời",
  },
  BC_SUB_LUYEN_TRI_SACH: {
    value: "BC_SUB_LUYEN_TRI_SACH",
    des: "Luyện trí(Tủ sách)",
  },
  BC_SUB_TU_SACH_NEN_TANG: {
    value: "BC_SUB_TU_SACH_NEN_TANG",
    des: "Khai mở Thân Tâm Trí",
  },
};

const statusBook = {
  new: {
    id: 1,
    key: "1",
    name: "Mới tạo",
    color: "blue",
  },
  active: {
    id: 2,
    key: "2",
    name: "Đang hoạt động",
    color: "green",
  },
  unactive: {
    id: -2,
    key: "-2",
    name: "Ngưng hoạt động",
    color: "red",
  },
};
const listStatusBook = Object.values(statusBook);
const findStatusBook = (id, field) => {
  const result = listStatusBook.find(e => e?.id === id * 1);
  return result?.[field] || result;
};
const requiredBook = {
  required: {
    id: 1,
    key: "1",
    name: "Bắt buộc",
    color: "green",
  },
  not_required: {
    id: -1,
    key: "-1",
    name: "Không bắt buộc",
    color: "red",
  },
};

const listRequiredBook = Object.values(requiredBook);
const findRequiredBook = (id, field) => {
  const result = listRequiredBook.find(e => e?.id === id * 1);
  return result?.[field] || result;
};

const statusChapter = {
  required: {
    id: 1,
    key: "1",
    name: "Bắt buộc",
    color: "green",
  },
  not_required: {
    id: -1,
    key: "-1",
    name: "Không bắt buộc",
    color: "red",
  },
};

const listStatusChapter = Object.values(statusChapter);
const findStatusChapter = (id, field) => {
  const result = listStatusChapter.find(e => e?.id === id * 1);
  return result?.[field] || result;
};

export {
  codeCategoryBook,
  statusBook,
  listStatusBook,
  findStatusBook,
  statusChapter,
  listStatusChapter,
  findStatusChapter,
  findRequiredBook,
  listRequiredBook,
  requiredBook,
};
