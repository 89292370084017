const actionConst = {
  category: {
    cover_image: "Ảnh bìa",
    status: "Trạng thái",
    name: "Tên danh mục",
  },
  post: {
    status: "Trạng thái",
    video: "Video",
    audio: "Audio",
    cover_image: "Hình ảnh",
    title: "Tiêu đề",
    comment_status: "Cho phép bình luận",
    content: "Nội dung bài viết",
    expert_info: "Chuyên gia",
    is_require_unlock: "Yêu cầu mở khóa",
    point_unlock: "Số hạt cà phê quy đổi",
    share_images: "Hình ảnh chia sẻ",
    product: "Sản phẩm",
  },
  BMI: {
    status: "Trạng thái",
    video: "Video",
    audio: "Audio",
    cover_image: "Hình ảnh",
    title: "Tiêu đề",
    comment_status: "Cho phép bình luận",
    content: "Nội dung",
  },
  book: {
    status: "Trạng thái",
    name: "Tên sách",
    audio: "Audio",
    cover_image: "Hình ảnh",
    des: "Mô tả",
    comment_status: "Cho phép bình luận",
    pdf: "Chương",
    is_require_unlock: "Yêu cầu bắt buộc",
    point_unlock: "Số hạt cà phê bắt buộc",
  },
  product: {
    status: "Trạng thái",
    total_stock: "SL tồn kho",
    name: "Tên sản phẩm",
    price: "Giá niêm yết",
    net_price: "Giá bán",
    des: "Mô tả sản phẩm",
    content: "Thông tin sản phẩm",
    cover_image: "Hình ảnh",
    group_ids: "Nhóm sản phẩm",
    code: "Mã sản phẩm",
  },
  specialist: {
    status: "Trạng thái",
    name: "Tên chuyên gia",
    cover_image: "Hình ảnh",
    expert_group: "Nhóm chuyên gia",
  },
  talkshow: {
    status: "Trạng thái",
    title: "Tiêu đề",
    content: "Nội dung",
    cover_image: "Ảnh bìa",
    address: "Địa chỉ",
    end_at: "Thời gian kết thúc",
    start_at: "Thời gian bắt đầu",
    comment_status: "Cho phép bình luận",
    expert: "Chuyên gia",
    stream_link: "Link livestream",
    is_require_unlock: "Yêu cầu bắt buộc",
    point_unlock: "Số hạt cà phê bắt buộc",
  },
  communityShare: {
    status: "Trạng thái",
    title: "Tiêu đề",
    description: "Mô tả",
    cover_image: "Ảnh bìa",
  },
  voucher: {
    status: "Trạng thái",
    title: "Tiêu đề",
    cover_image: "Ảnh bìa",
    end_at: "Thời gian kết thúc",
    start_at: "Thời gian bắt đầu",
    value_discount_max: "Giảm tối đa",
    discount: "Tỉ lệ giảm giá",
    description: "Nội dung mô tả",
    short_description: "Mô tả ngắn",
    value_order_min: "Giá trị tối thiểu của tổng sản phẩm áp dụng",
    object_type: "Đối tượng áp dụng",
    value_point: "Số hạt cà phê quy đổi",
    value: "Giá trị Voucher",
    pay_type: "Hình thức thanh toán",
  },
  meditationMusic: {
    status: "Trạng thái",
    audio: "Audio",
    name: "Tên nhạc Thiền",
  },
  meditationVideo: {
    status: "Trạng thái",
    video: "Video",
    name: "Tên video Thiền",
  },
  games: {
    status: "Trạng thái",
    rate: "Điểm quy đổi",
    times: "Thời gian 1 lượt chơi",
    total_turn: "Số lượt chơi trong ngày",
    total_question: "Số câu hỏi trên 1 lượt chơi",
    name: "Tên trò chơi",
    des: "Nội dung hướng dẫn trò chơi",
  },
  questionGames: {
    status: "Trạng thái",
    answer: "Đáp án",
    image_des: "Hình ảnh",
    content: "Tiêu đề",
    point: "Số điểm trên 1 câu hỏi",
  },
  guestVideo: {
    name: "Tên video Thiền",
    status: "Trạng thái",
    description: "Mô tả",
    video: "Video",
  },
  vat: {
    value: "Tỉ lệ VAT áp dụng",
    time: "Thời gian áp dụng",
  },
};

export default actionConst;
