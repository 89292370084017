import Icon from "@ant-design/icons";
import { LIST_PERMISSION } from "_constants/Permission/permission";
import { urlTalkshow } from "_constants/talkshow";
import { awakening_community } from "layouts/Icon";
import {
  CommunityShareCreate,
  CommunityShareDetail,
  CommunityShareEventCreate,
  CommunityShareEventDetail,
  CommunityShareEventList,
  CommunityShareList,
  SharingExperiencesCreate,
  SharingExperiencesDetail,
  SharingExperiencesList,
} from "./lazy-import";

const AWAKENING_COMMUNITY = {
  title: "Cộng đồng tỉnh thức",
  label: "Cộng đồng tỉnh thức",
  icon: <Icon component={awakening_community} />,
  display: 1,
  children: [
    {
      key: `/${urlTalkshow.EC_CONG_DONG_TINH_THUC.url}`,
      title: "Chương trình, sự kiện",
      label: "Chương trình, sự kiện ",
      component: props => <CommunityShareEventList {...props} />,
      display: 1,
      action_key: LIST_PERMISSION.COMMUNITY_EVENT_VIEW,
    },
    {
      key: `/${urlTalkshow.EC_CONG_DONG_TINH_THUC.url}/add`,
      title: "Thêm sự kiện",
      label: "Thêm sự kiện",
      component: props => <CommunityShareEventCreate {...props} />,
      action_key: LIST_PERMISSION.COMMUNITY_EVENT_ADD,
    },
    {
      key: `/${urlTalkshow.EC_CONG_DONG_TINH_THUC.url}/:id`,
      title: "Chi tiết sự kiện",
      label: "Chi tiết sự kiện",
      component: props => <CommunityShareEventDetail {...props} />,
      action_key: LIST_PERMISSION.COMMUNITY_EVENT_VIEW,
    },
    {
      key: `/community-share`,
      title: "Kết nối, chia sẻ",
      label: "Kết nối, chia sẻ",
      component: props => <CommunityShareList {...props} />,
      display: 1,
      action_key: LIST_PERMISSION.COMMUNITY_SHARE_VIEW,
    },
    {
      key: `/community-share/add`,
      title: "Thêm kết nối, chia sẻ",
      label: "Thêm kết nối, chia sẻ",
      component: props => <CommunityShareCreate {...props} />,
      action_key: LIST_PERMISSION.COMMUNITY_SHARE_ADD,
    },
    {
      key: `/community-share/:id`,
      title: "Chi tiết kết nối, chia sẻ",
      label: "Chi tiết kết nối, chia sẻ",
      component: props => <CommunityShareDetail {...props} />,
      action_key: LIST_PERMISSION.COMMUNITY_SHARE_VIEW,
    },

    {
      key: `/sharing-experiances`,
      title: "Chia sẻ kinh nghiệm từ các thành viên",
      label: "Chia sẻ kinh nghiệm từ các thành viên",
      component: props => <SharingExperiencesList {...props} />,
      display: 1,
      action_key: LIST_PERMISSION.COMMUNITY_CONNECT_VIEW,
    },
    {
      key: `/sharing-experiances/:id`,
      title: "Chi tiết bài viết",
      label: "Chi tiết bài viết",
      component: props => <SharingExperiencesDetail {...props} />,
      action_key: LIST_PERMISSION.COMMUNITY_CONNECT_VIEW,
    },
    {
      key: `/sharing-experiances/add`,
      title: "Thêm bài viết",
      label: "Thêm bài viết",
      component: props => <SharingExperiencesCreate {...props} />,
      action_key: LIST_PERMISSION.COMMUNITY_CONNECT_ADD,
    },
  ],
};
export default AWAKENING_COMMUNITY;
