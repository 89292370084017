const statusGames = {
  active: {
    id: 1,
    key: "1",
    name: "Đang hoạt động",
    color: "green",
  },
  unactive: {
    id: -1,
    key: "-1",
    name: "Tạm ngưng",
    color: "red",
  },
};
const listStatusGames = Object.values(statusGames);
const findStatusGames = (id, field) => {
  const result = listStatusGames.find(e => e?.id * 1 === id * 1);
  return result?.[field] || result;
};

const statusQuestionGames = {
  active: {
    id: 1,
    key: "1",
    name: "Đang hoạt động",
    color: "green",
  },
  unactive: {
    id: -1,
    key: "-1",
    name: "Tạm ngưng",
    color: "red",
  },
};
const listStatusQuestionGames = Object.values(statusQuestionGames);
const findStatusQuestionGames = (id, field) => {
  const result = listStatusQuestionGames.find(e => e?.id * 1 === id * 1);
  return result?.[field] || result;
};

export {
  statusGames,
  listStatusGames,
  findStatusGames,
  statusQuestionGames,
  listStatusQuestionGames,
  findStatusQuestionGames,
};
