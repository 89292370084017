import { SettingOutlined } from "@ant-design/icons";
import { LIST_PERMISSION } from "_constants/Permission/permission";
import { VersionManagement } from "./lazy-import";

const VERSION_MANAGEMENT = [
  {
    key: "/version",
    title: "Quản lý hệ thống",
    label: "Quản lý hệ thống",
    icon: <SettingOutlined />,
    component: props => <VersionManagement {...props} />,
    action_key: LIST_PERMISSION.VERSION_VIEW,
    display: 1,
  },
];
export default VERSION_MANAGEMENT;
