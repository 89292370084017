import Icon from "@ant-design/icons";

import { listPathname } from "_constants/ListPathname/pathname";
import { LIST_PERMISSION } from "_constants/Permission/permission";
import { order } from "layouts/Icon";
import {
  ManagerShipFeeCreate,
  ManagerShipFeeDetail,
  ManagerShipFeeList,
} from "./lazy-import";

const SHIPPING_FEE = [
  {
    key: listPathname.manager_ship_fee.sub,
    title: "Quản lý phí vận chuyển",
    label: "Quản lý phí vận chuyển",
    icon: <Icon component={order} />,
    component: props => <ManagerShipFeeList {...props} />,
    action_key: LIST_PERMISSION.DELIVERY_FEE_VIEW,
    display: 1,
  },
  {
    key: listPathname.manager_ship_fee.details,
    title: "Chi tiết phí vận chuyển",
    label: "Chi tiết phí vận chuyển",
    component: props => <ManagerShipFeeDetail {...props} />,
    action_key: LIST_PERMISSION.DELIVERY_FEE_VIEW,
  },
  {
    key: listPathname.manager_ship_fee.add,
    title: "Thêm phí vận chuyển",
    label: "Thêm phí vận chuyển",
    component: props => <ManagerShipFeeCreate {...props} />,
    action_key: LIST_PERMISSION.DELIVERY_FEE_ADD,
  },
];

export default SHIPPING_FEE;
