import React from "react";
import Error from "./Error";
import Loading from "./Loading";

const ComponentStatusProcessing = ({ children, loading, error }) => {
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }
  return children;
};

export default React.memo(ComponentStatusProcessing);
