const statusGuestVideo = {
  active: {
    id: 1,
    key: "1",
    name: "Đang hoạt động",
    color: "green",
  },
  stopped: {
    id: -1,
    key: "-1",
    name: "Ngưng hoạt động",
    color: "red",
  },
};
const listStatusGuestVideo = Object.values(statusGuestVideo);
const findStatusGuestVideo = (id, field) => {
  const result = listStatusGuestVideo.find(e => e.id === id * 1);
  return result?.[field] || result;
};

export { statusGuestVideo, listStatusGuestVideo, findStatusGuestVideo };
