const statusPost = {
  new: {
    id: 1,
    key: "1",
    name: "Mới tạo",
    color: "blue",
  },
  active: {
    id: 2,
    key: "2",
    name: "Đang hoạt động",
    color: "green",
  },
  unActive: {
    id: -2,
    key: "-2",
    name: "Ngưng hoạt động",
    color: "red",
  },
};
const statusPostActiveList = [
  {
    ...statusPost.new,
    actionList: [statusPost.new, statusPost.active, statusPost.unActive],
  },
  {
    ...statusPost.active,
    actionList: [statusPost.active, statusPost.unActive],
  },
  {
    ...statusPost.unActive,
    actionList: [statusPost.active, statusPost.unActive],
  },
];
const listStatusPost = Object.values(statusPost);
const findStatusPost = (id, field) => {
  const result = statusPostActiveList.find(e => e.id === id * 1);
  return result?.[field] || result;
};

const statusComment = {
  active: {
    id: 1,
    key: "1",
    name: "Cho phép",
    color: "green",
  },
  unActive: {
    id: -1,
    key: "-1",
    name: "Không cho phép",
    color: "red",
  },
};
const listStatusComment = Object.values(statusComment);
const findStatusComment = (id, field) => {
  const result = listStatusComment.find(e => e.id === id * 1);
  return result?.[field] || result;
};

const statusOpen = {
  required: {
    id: 1,
    key: "1",
    name: "Bắt buộc",
    color: "green",
  },
  not_required: {
    id: -1,
    key: "-1",
    name: "Không bắt buộc",
    color: "red",
  },
};

const listStatusOpen = Object.values(statusOpen);
const findStatusOpen = (id, field) => {
  const result = listStatusOpen.find(e => e?.id === id * 1);
  return result?.[field] || result;
};

export {
  listStatusPost,
  findStatusPost,
  statusPost,
  statusPostActiveList,
  listStatusComment,
  findStatusComment,
  statusComment,
  statusOpen,
  listStatusOpen,
  findStatusOpen,
};
