import { CheckCircleFilled } from "@ant-design/icons";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";

const ModelSuccessConfirm = ({
  title = "",
  open = false,
  onOk = () => null,
  linkList = "/",
}) => {
  const navigate = useNavigate();
  return (
    <Modal
      title={
        <>
          <CheckCircleFilled
            style={{ color: "#52c41a", marginRight: "10px", fontSize: "21px" }}
          />
          {title}
        </>
      }
      open={open}
      onOk={onOk}
      onCancel={() => navigate(linkList)}
      okText={'Tạo mới'}
      cancelText={'Đến danh sách'}
      closable={false}
      keyboard={false}
      maskClosable={false}
    >
      <p>Bạn có muốn tạo thêm không?</p>
    </Modal>
  );
};

export default ModelSuccessConfirm;
