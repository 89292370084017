const statusSpecialist = {
  new: {
    id: 1,
    key: "1",
    name: "Mới tạo",
    color: "blue",
    disabled: true,
    action_key: [
      {
        id: 1,
        key: "1",
        name: "Mới tạo",
        color: "blue",
      },
      {
        id: 2,
        key: "2",
        name: "Đang hoạt động",
        color: "green",
      },
      {
        id: -2,
        key: "-2",
        name: "Ngưng hoạt động",
        color: "red",
      },
    ],
  },
  active: {
    id: 2,
    key: "2",
    name: "Đang hoạt động",
    color: "green",
    action_key: [
      {
        id: 2,
        key: "2",
        name: "Đang hoạt động",
        color: "green",
      },
      {
        id: -2,
        key: "-2",
        name: "Ngưng hoạt động",
        color: "red",
      },
    ],
  },
  stopped: {
    id: -2,
    key: "-2",
    name: "Ngưng hoạt động",
    color: "red",
    action_key: [
      {
        id: 2,
        key: "2",
        name: "Đang hoạt động",
        color: "green",
      },
      {
        id: -2,
        key: "-2",
        name: "Ngưng hoạt động",
        color: "red",
      },
    ],
  },
};
const listStatusSpecialist = Object.values(statusSpecialist);
const findStatusSpecialList = (id, field) => {
  const result = listStatusSpecialist.find(e => e.id === id * 1);
  return result?.[field] || result;
};
const typeSpecialist = {
  "coffee-meditation-expert": {
    key: "coffee-meditation-expert",
    keyTalkShow: "coffee-meditation-talkshow",
    code: "EG_CHUYEN_GIA_THIEN_CA_PHE",
    name: "Chuyên gia về Thiền cà phê",
    action_key: "VIEW_USER",
    id: 2,
  },
  "contemporary-meditation-expert": {
    key: "contemporary-meditation-expert",
    keyTalkShow: "contemporary-meditation-talkshow",
    code: "EG_CHUYEN_GIA_THIEN_DUONG_DAI",
    name: "Chuyên gia về Thiền đương đại",
    action_key: "VIEW_USER",
    id: 3,
  },
  "yoga-expert": {
    key: "yoga-expert",
    keyTalkShow: "yoga-talkshow",
    code: "EG_CHUYEN_GIA_YOGA",
    name: "Chuyên gia về Yoga",
    action_key: "VIEW_USER",
    id: 4,
  },
  therapist: {
    code: "EG_CHUYEN_GIA_TRI_LIEU",
    key: "therapist-specilist",
    keyTalkShow: "therapist-talkshow",
    name: "Chuyên gia Trị liệu",
    action_key: "VIEW_USER",
    id: 5,
  },
};
const listTypeSpecialList = Object.values(typeSpecialist);

const findTypeSpecialList = (code, field) => {
  const result = listTypeSpecialList.find(e => e.code === code);
  return result?.[field] ?? result;
};

export {
  statusSpecialist,
  findStatusSpecialList,
  listStatusSpecialist,
  typeSpecialist,
  listTypeSpecialList,
  findTypeSpecialList,
};
