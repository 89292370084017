import axios from "axios";
import { METHOD, storage } from "_constants";

const axiosTempDefault = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});
axiosTempDefault.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem(storage.ACCESS_TOKEN);
    if (token) {
      config.headers.common["Authorization"] = "Bearer " + token;
    }

    return config;
  },

  function (error) {
    return Promise.reject(error);
  }
);

axiosTempDefault.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    if (error?.response?.status === 401) {
      localStorage.removeItem(storage.ACCESS_TOKEN);
      localStorage.removeItem(storage.USER);
      window.location.replace("/");
    }

    return Promise.reject(error);
  }
);

const axiosClient = type => {
  let domain = process.env.REACT_APP_API_URL;
  if (type === METHOD.GET) {
    domain = process.env.REACT_APP_API_READ_URL;
  }
  if (type === METHOD.NOTI) {
    domain = process.env.REACT_APP_API_NOTIFY;
  }
  if (type === METHOD.SOCKET) {
    domain = process.env.REACT_APP_SOCKET_API_URL;
  }
  if (type === METHOD.PAY) {
    domain = process.env.REACT_APP_ORDER_PAY;
  }
  axiosTempDefault.defaults.baseURL = domain + "/v1/";
  return axiosTempDefault;
};

export default axiosClient;

export const getIpAddress = () =>
  axios.get("https://api.ipify.org?format=json");
