import { DashboardOutlined } from "@ant-design/icons";
import { LIST_PERMISSION } from "_constants/Permission/permission";
import { Dashboard } from "./lazy-import";

const DASHBOARD = [
  {
    key: "/dashboard",
    title: "Dashboard",
    label: "Dashboard",
    icon: <DashboardOutlined />,
    component: props => <Dashboard {...props} />,
    action_key: LIST_PERMISSION.DASHBOARD_VIEW,
    display: 1,
  },
];

export default DASHBOARD;
