import Icon from "@ant-design/icons";

import { LIST_PERMISSION } from "_constants/Permission/permission";
import { exploit } from "layouts/Icon";
import {
  BMIDetail,
  BMIList,
  BMIResultList,
  EatHealthyPostCreate,
  EatHealthyPostDetail,
  EatHealthyPostList,
  MCDiscoverPostCreate,
  MCDiscoverPostDetail,
  MCDiscoverPostList,
} from "./lazy-import";

const MEDITATION_DISCOVERY = [
  {
    title: "Khai phá Thiền cà phê",
    label: "Khai phá Thiền cà phê",
    icon: <Icon component={exploit} />,
    display: 1,
    children: [
      {
        key: "/meditation-coffee-discovery-bmi",
        title: "Chỉ số BMI",
        label: "Chỉ số BMI",
        component: props => <BMIList {...props} />,
        display: 1,
        action_key: LIST_PERMISSION.BMI_VIEW,
      },
      {
        key: "/meditation-coffee-discovery-bmi-result",
        title: "Kết quả chỉ số BMI",
        label: "Kết quả chỉ số BMI",
        component: props => <BMIResultList {...props} />,
        display: 1,
        action_key: LIST_PERMISSION.BMI_RESULT_VIEW,
      },
      {
        key: "/meditation-coffee-discovery-bmi/:id",
        title: "Chi tiết chỉ số BMI",
        label: "Chi tiết chỉ số BMI",
        component: props => <BMIDetail {...props} />,
        action_key: LIST_PERMISSION.BMI_VIEW,
      },
      {
        key: "/meditation-coffee-discovery-post",
        title: "Bài thiền cà phê",
        label: "Bài thiền cà phê",
        component: props => <MCDiscoverPostList {...props} />,
        display: 1,
        action_key: LIST_PERMISSION.DISCOVERY_MEDITATION_POST_VIEW,
      },
      {
        key: "/meditation-coffee-discovery-post/:id",
        title: "Chi tiết bài viết",
        label: "Chi tiết bài viết",
        component: props => <MCDiscoverPostDetail {...props} />,
        action_key: LIST_PERMISSION.DISCOVERY_MEDITATION_POST_VIEW,
      },
      {
        key: "/meditation-coffee-discovery-post/add",
        title: "Thêm bài viết",
        label: "Thêm bài viết",
        component: props => <MCDiscoverPostCreate {...props} />,
        action_key: LIST_PERMISSION.DISCOVERY_MEDITATION_POST_ADD,
      },
      {
        key: "/eat-healthy-post",
        title: "Thực hành Lối sống tỉnh thức",
        label: "Thực hành Lối sống tỉnh thức",
        component: props => <EatHealthyPostList {...props} />,
        display: 1,
        action_key: LIST_PERMISSION.EAT_HEALTHY_POST_VIEW,
      },
      {
        key: "/eat-healthy-post/:id",
        title: "Chi tiết hướng dẫn",
        label: "Chi tiết hướng dẫn",
        component: props => <EatHealthyPostDetail {...props} />,
        action_key: LIST_PERMISSION.EAT_HEALTHY_POST_VIEW,
      },
      {
        key: "/eat-healthy-post/add",
        title: "Thêm hướng dẫn",
        label: "Thêm hướng dẫn",
        component: props => <EatHealthyPostCreate {...props} />,
        action_key: LIST_PERMISSION.EAT_HEALTHY_POST_ADD,
      },
    ],
  },
];
export default MEDITATION_DISCOVERY;
