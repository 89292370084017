const statusBMI = {
  active: {
    id: 1,
    key: "1",
    name: "Đang hoạt động",
    color: "green",
  },
  unactive: {
    id: -1,
    key: "-1",
    name: "Tạm ngưng",
    color: "red",
  },
};
const listStatusBMI = Object.values(statusBMI);
const findStatusBMI = (id, field) => {
  const result = listStatusBMI.find(e => e?.id * 1 === id * 1);
  return result?.[field] || result;
};

const typeBMI = {
  BMI_UNDERWEIGHT: {
    code: "BMI_UNDERWEIGHT",
    name: "Thiếu cân",
  },
  BMI_HEALTHY: {
    code: "BMI_HEALTHY",
    name: "Bình thường",
  },
  BMI_OVERWEIGHT: {
    code: "BMI_OVERWEIGHT",
    name: "Thừa cân",
  },
};
const listTypeBMI = Object.values(typeBMI);
export { statusBMI, listStatusBMI, findStatusBMI, typeBMI, listTypeBMI };
