import Icon from "@ant-design/icons";

import { listPathname } from "_constants/ListPathname/pathname";
import { LIST_PERMISSION } from "_constants/Permission/permission";
import { promotion } from "layouts/Icon";
import { PromotionCreate, PromotionDetail, PromotionList } from "./lazy-import";

const PROMOTION = [
  {
    key: listPathname.promotion_management.sub,
    title: "Quản lý khuyến mãi",
    label: "Quản lý khuyến mãi",
    icon: <Icon component={promotion} />,
    component: props => <PromotionList {...props} />,
    action_key: LIST_PERMISSION.PROMOTION_VIEW,
    display: 1,
  },
  {
    key: listPathname.promotion_management.detail,
    title: "Chi tiết khuyến mãi",
    label: "Chi tiết khuyến mãi",
    component: props => <PromotionDetail {...props} />,
    action_key: LIST_PERMISSION.PROMOTION_VIEW,
  },
  {
    key: listPathname.promotion_management.add,
    title: "Tạo khuyến mãi",
    label: "Tạo khuyến mãi",
    component: props => <PromotionCreate {...props} />,
    action_key: LIST_PERMISSION.PROMOTION_ADD,
  },
];
export default PROMOTION;
