import Icon from "@ant-design/icons";
import { LIST_PERMISSION } from "_constants/Permission/permission";
import { sticker } from "layouts/Icon";
import {
  StickerManagement,
  StickerManagementCreate,
  StickerManagementDetail,
} from "./lazy-import";

const STICKER_MANAGEMENT = [
  {
    key: "/stickers",
    title: "Quản lý Sticker",
    label: "Quản lý Sticker",
    icon: <Icon component={sticker} />,
    component: props => <StickerManagement {...props} />,
    action_key: LIST_PERMISSION.STICKER_VIEW,
    display: 1,
  },
  {
    key: "/stickers/:id",
    title: "Chi tiết Sticker",
    label: "Chi tiết Sticker",
    component: props => <StickerManagementDetail {...props} />,
    action_key: LIST_PERMISSION.STICKER_VIEW,
  },
  {
    key: "/stickers/add",
    title: "Thêm Sticker",
    label: "Thêm Sticker",
    component: props => <StickerManagementCreate {...props} />,
    action_key: LIST_PERMISSION.STICKER_ADD,
  },
];

export default STICKER_MANAGEMENT;
