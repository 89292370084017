import Icon from "@ant-design/icons";
import { LIST_PERMISSION } from "_constants/Permission/permission";
import { urlTalkshow } from "_constants/talkshow";
import { news } from "layouts/Icon";
import {
  EventsCreate,
  EventsDetail,
  EventsList,
  NewProductCreate,
  NewProductDetail,
  NewProductList,
  NewsLetterCreate,
  NewsLetterDetail,
  NewsLetterList,
} from "./lazy-import";

const MEDITATION_NEWS = [
  {
    title: "Bản tin Thiền cà phê",
    label: "Bản tin Thiền cà phê",
    icon: <Icon component={news} />,
    display: 1,
    children: [
      {
        key: "/news-letter",
        title: "Thông tin, thông báo",
        label: "Thông tin, thông báo",
        component: props => <NewsLetterList {...props} />,
        display: 1,
        action_key: LIST_PERMISSION.NEWS_NOTIFICATION_POST_VIEW,
      },
      {
        key: "/news-letter/add",
        title: "Thêm bài viết",
        label: "Thêm bài viết",
        component: props => <NewsLetterCreate {...props} />,
        action_key: LIST_PERMISSION.NEWS_NOTIFICATION_POST_ADD,
      },
      {
        key: "/news-letter/:id",
        title: "Chi tiết bài viết",
        label: "Chi tiết bài viết",
        component: props => <NewsLetterDetail {...props} />,
        action_key: LIST_PERMISSION.NEWS_NOTIFICATION_POST_VIEW,
      },
      {
        key: `/${urlTalkshow.EC_BAN_TIN.url}`,
        title: "Sự kiện",
        label: "Sự kiện",
        component: props => <EventsList {...props} />,
        display: 1,
        action_key: LIST_PERMISSION.NEWS_EVENT_POST_VIEW,
      },
      {
        key: `/${urlTalkshow.EC_BAN_TIN.url}/add`,
        title: "Thêm sự kiện",
        label: "Thêm sự kiện",
        component: props => <EventsCreate {...props} />,
        action_key: LIST_PERMISSION.NEWS_EVENT_POST_ADD,
      },
      {
        key: `/${urlTalkshow.EC_BAN_TIN.url}/:id`,
        title: "Chi tiết sự kiện",
        label: "Chi tiết sự kiện",
        component: props => <EventsDetail {...props} />,
        action_key: LIST_PERMISSION.NEWS_EVENT_POST_VIEW,
      },
      {
        key: "/new-product",
        title: "Giới thiệu sản phẩm mới",
        label: "Giới thiệu sản phẩm mới",
        component: props => <NewProductList {...props} />,
        display: 1,
        action_key: LIST_PERMISSION.NEWS_INTRODUCE_PRODUCT_POST_VIEW,
      },
      {
        key: "/new-product/add",
        title: "Thêm bài viết",
        label: "Thêm bài viết",
        component: props => <NewProductCreate {...props} />,
        action_key: LIST_PERMISSION.NEWS_INTRODUCE_PRODUCT_POST_ADD,
      },
      {
        key: "/new-product/:id",
        title: "Chi tiết bài viết",
        label: "Chi tiết bài viết",
        component: props => <NewProductDetail {...props} />,
        action_key: LIST_PERMISSION.NEWS_INTRODUCE_PRODUCT_POST_VIEW,
      },
    ],
  },
];
export default MEDITATION_NEWS;
