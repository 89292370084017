import Icon from "@ant-design/icons";
import { LIST_PERMISSION } from "_constants/Permission/permission";
import { game } from "layouts/Icon";
import {
  GameCreate,
  GameDetail,
  GameList,
  QuestionGameCreate,
  QuestionGameDetail,
  QuestionGameList,
  ResultGameDetail,
  ResultGameList,
} from "./lazy-import";

const GAME_MANAGEMENT = [
  {
    key: `/games-manager`,
    label: "Trò chơi",
    title: "Trò chơi",
    icon: <Icon component={game} />,
    display: 1,
    children: [
      {
        key: "/games",
        title: "Thông tin chung",
        label: "Thông tin chung",
        component: props => <GameList {...props} />,
        display: 1,
        action_key: LIST_PERMISSION.GAME_INFO_VIEW,
      },
      {
        key: "/games/:id",
        label: "Chi tiết trò chơi",
        title: "Chi tiết trò chơi",
        component: props => <GameDetail {...props} />,
        action_key: LIST_PERMISSION.GAME_INFO_VIEW,
      },
      {
        key: "/games/add",
        label: "Chi tiết trò chơi",
        title: "Chi tiết trò chơi",
        component: props => <GameCreate {...props} />,
        action_key: LIST_PERMISSION.GAME_INFO_VIEW,
      },
      {
        key: "/question-games",
        title: "Câu hỏi",
        label: "Câu hỏi",
        component: props => <QuestionGameList {...props} />,
        display: 1,
        action_key: LIST_PERMISSION.GAME_QUESTION_VIEW,
      },
      {
        key: "/question-games/:id",
        label: "Chi tiết trò chơi",
        title: "Chi tiết trò chơi",
        component: props => <QuestionGameDetail {...props} />,
        action_key: LIST_PERMISSION.GAME_QUESTION_VIEW,
      },
      {
        key: "/question-games/add",
        label: "Chi tiết trò chơi",
        title: "Chi tiết trò chơi",
        component: props => <QuestionGameCreate {...props} />,
        action_key: LIST_PERMISSION.GAME_QUESTION_ADD,
      },
      {
        key: "/games-result",
        title: "Kết quả thành tích",
        label: "Kết quả thành tích",
        component: props => <ResultGameList {...props} />,
        display: 1,
        action_key: LIST_PERMISSION.GAME_RESULT_VIEW,
      },
      {
        key: "/games-result/:id",
        label: "Chi tiết trò chơi",
        title: "Chi tiết trò chơi",
        component: props => <ResultGameDetail {...props} />,
        action_key: LIST_PERMISSION.GAME_RESULT_VIEW,
      },
    ],
  },
];
export default GAME_MANAGEMENT;
