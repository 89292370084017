const statusCommunityShare = {
    new: {
        id: 1,
        key: "1",
        name: "Chờ kiểm duyệt",
        label: "Chờ kiểm duyệt",
        color: "blue",
    },
    active: {
        id: 2,
        key: "2",
        name: "Đã kiểm duyệt",
        label: "Đã kiểm duyệt",
        color: "green",
    },
    unactive: {
        id: -2,
        key: "-2",
        name: "Từ chối kiểm duyệt",
        label: "Từ chối kiểm duyệt",
        color: "red",
    },
  };
  const listCommunityShare = Object.values(statusCommunityShare);
  const findCommunityShare = (id, field) => {
    const result = listCommunityShare.find(e => e.id === id * 1);
    return result?.[field] || result;
  };
  export { listCommunityShare, findCommunityShare };
  