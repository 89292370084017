import { defaultPagination, storage } from "_constants";
import { Typography } from "antd";
import _ from "lodash";

export const getLocalStorage = key => {
  return localStorage[key ?? storage.ACCESS_TOKEN];
};

export const getTotalWidthCols = (columns = []) =>
  _.reduce(
    columns,
    (prev, curr) => {
      return prev + (curr?.width ?? 0);
    },
    0
  );

export const handleChangeTable = (
  current = defaultPagination.page,
  sorter,
  onChange
) => {
  const sort = sorter?.order?.slice(0, -3);
  let result = {
    page: current,
  };
  if (sorter?.order) {
    result = {
      ...result,
      order_by: sorter?.columnKey + " " + sort,
    };
  } else {
    result.order_by = undefined;
  }
  onChange(result);
};

export const TextToolTip = ({
  text = "-",
  style = {},
  className = "",
  rows = 2,
}) => {
  return (
    <Typography.Paragraph
      ellipsis={{ tooltip: text, rows: rows }}
      style={{ marginBottom: 0, wordBreak: "break-word", ...style }}
      className={className}
    >
      <div dangerouslySetInnerHTML={{ __html: text }}></div>
    </Typography.Paragraph>
  );
};

export const arrayToString = (arr = []) => {
  if (!_.isArray(arr)) {
    return "[]";
  }
  return JSON.stringify(arr);
};
