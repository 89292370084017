import { typeFilter } from "_constants/common";
import _ from "lodash";
import moment from "moment";
import queryString from "query-string";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { cleanObject } from "utils/common";

const processValueItem = (value, schema) => {
  const defaultValue = schema?.defaultValue;
  const type = schema?.type;
  const itemType = schema?.itemType;

  if (!value) return defaultValue;
  try {
    if (type === typeFilter.number) return Number(value);
    if (type === typeFilter.boolean) return value === "true" ? true : false;
    if (type === typeFilter.moment) return moment(value);
    if (type === typeFilter.array)
      return value
        .split(",")
        .map(item => (itemType === typeFilter.string ? item : Number(item)));
    if (type === typeFilter.string) return String(value).trim();
    return value;
  } catch (error) {
    return;
  }
};
const getDefaultObj = schema => {
  const defaultObject = {};
  schema.forEach(e => {
    if (e?.defaultValue) {
      defaultObject[e?.name] = e?.defaultValue;
    }
  });
  return defaultObject;
};
//This version is formatted with Array Filter
function useFilterV2(filterSchema = []) {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = queryString.parse(searchParams.toString());
  const defaultValue = getDefaultObj(filterSchema);

  const filter = useMemo(() => {
    const queryParams = params;
    const listFilterInParams = _.keyBy(filterSchema, "name");
    for (const key in queryParams) {
      queryParams[key] = processValueItem(
        queryParams[key],
        listFilterInParams[key]
      );
    }
    return queryParams;
  }, [filterSchema, params]);

  const apiFilter = useMemo(() => {
    return { ...defaultValue, ...filter };
  }, [filter]);

  const handleResetFilter = (objReset = {}) => {
    setSearchParams({ ...defaultValue, ...objReset });
  };

  const handleFilterChange = (newFilter = {}) => {
    const cloneFilter = cleanObject({ ...filter, ...newFilter });
    setSearchParams({ ...defaultValue, ...cloneFilter });
  };

  return {
    filter,
    apiFilter,
    onFilterReset: handleResetFilter,
    onFilterChange: handleFilterChange,
  };
}

export default useFilterV2;
