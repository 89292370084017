import { defaultPagination } from "_constants";
import { Card, ConfigProvider, Empty, Table } from "antd";
import { getTotalWidthCols, handleChangeTable } from "utils";

function CardTable({
  title,
  columns,
  loading,
  data,
  onChange = () => null,
  pagination = defaultPagination,
  propsTable = {},
  textTotal = "items",
  children,
  ...rest
}) {
  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            fontWeightStrong: 400,
          },
        },
      }}
    >
      <Card
        title={
          <p
            className="fs-6"
            style={{
              marginBottom: "0",
              fontWeight: 700,
              textTransform: "uppercase",
            }}
          >
            {title + ` (${data?.total ?? 0})`}
          </p>
        }
        {...rest}
      >
        {children}
        <Table
          locale={{
            emptyText: (
              <Empty
                description="Không có dữ liệu"
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            ),
          }}
          columns={columns}
          loading={loading}
          dataSource={data?.data}
          scroll={{ x: getTotalWidthCols(columns) }}
          onChange={({ current }, _, sorter) =>
            handleChangeTable(current, sorter, onChange)
          }
          pagination={{
            total: data?.total,
            pageSize: pagination.page_size || pagination.perPage,
            current: pagination.page,
            size: pagination.size,
            showSizeChanger: false,
            showLessItems: true,
            showTotal: (total, range) =>
              `${range[0]} - ${range[1]} / ${total} ${textTotal}`,
            ...propsTable?.pagination,
          }}
          {...propsTable}
        />
      </Card>
    </ConfigProvider>
  );
}

CardTable.propTypes = {};

export default CardTable;
