import { PlusOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import React, { memo } from "react";
import { Link } from "react-router-dom";
import ExportButton from "shared_components/ExportButton";

const RenderExtra = ({
  handleExport = () => null,
  disabled,
  hasPerExport = false,
  hasPerAdd = false,
  textAdd = "",
  linkAdd = "/",
  nameFileExport = "File export",
}) => {
  return (
    <Space>
      {hasPerExport && (
        <ExportButton
          title={"Xuất dữ liệu"}
          disabled={disabled}
          func={handleExport}
          file_name={nameFileExport}
        />
      )}
      {hasPerAdd && (
        <Link to={linkAdd}>
          <Button type="primary">
            <PlusOutlined /> {textAdd}
          </Button>
        </Link>
      )}
    </Space>
  );
};

export default memo(RenderExtra);
