import {
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Spin,
  Typography,
} from "antd";
import libraryAPI from "apis/Library/libraryAPI";
import { useFilter } from "hooks";
import _ from "lodash";
import moment from "moment";
import { useQuery } from "react-query";
import { checkDisableDate, renderReadFile } from "utils/common";
import { statusLibrary, typeFile } from "_constants/library";

export default function ModalLibrary({
  openModal,
  setOpenModal,
  type,
  handleSelect,
}) {
  const [form] = Form.useForm();
  const handleVideo = val => {
    const vid = document.getElementsByClassName("video-lib");
    var arr = Array.prototype.slice.call(vid);
    arr.forEach(e => {
      if (e.id === val.target.id) {
        e.play();
      } else {
        e.pause();
      }
    });
  };
  const { filter, apiFilter, onFilterChange, onFilterReset } = useFilter([
    {
      name: "name",
      type: String,
    },
    {
      name: "created_from",
      type: moment,
    },
    {
      name: "created_to",
      type: moment,
    },
  ]);

  const handleOk = () => {};
  const handleCancel = () => {
    form.setFieldsValue({
      name: undefined,
      created_from: undefined,
      created_to: undefined,
    });
    onFilterReset();
    setOpenModal(false);
  };
  const checkType = type => {
    const arr = {
      PDF: "book",
      image: "other",
      audio: "audio",
      video: "video",
      other: "other",
    };
    return arr?.[type];
  };
  const { data = {}, isLoading } = useQuery(
    ["libList", apiFilter, type],
    () =>
      libraryAPI.getAll({
        ...apiFilter,
        library_id: typeFile?.[checkType(type)].key,
        status: statusLibrary.active.id,
      }),
    { enabled: openModal }
  );
  const renderType = (type, link) => {
    if (type === "image" || type === "other") {
      return (
        <img
          alt="img-upload"
          style={{
            height: 230,
            width: 200,
            objectFit: "contain",
            border: "1px solid #ddd",
          }}
          src={link}
          className="img-upload"
        />
      );
    }
    if (type === "video") {
      return (
        <video
          className="video-lib"
          width="200"
          playsInline
          height="100"
          id={link}
          controls
          onPlay={handleVideo}
        >
          <track default kind="captions"></track>
          <source src={link} type="video/mp4" />
        </video>
      );
    }
    if (type === "audio") {
      return (
        <audio
          width="200"
          height="100"
          controls
          className="mt-1"
          style={{ width: "250px" }}
        >
          <track default kind="captions"></track>
          <source src={link} type="audio/ogg" />
        </audio>
      );
    }
    if (type === "PDF" || type === "book") {
      return (
        <div>
          <a href={link} target="_blank" rel="noreferrer">
            Xem file PDF
          </a>
        </div>
      );
    }
    return;
  };
  const handleChangeFilter = _.debounce(
    () => onFilterChange(form.getFieldsValue()),
    600
  );

  return (
    <Modal
      title="Thư viện"
      open={openModal}
      onOk={handleOk}
      okText={false}
      cancelText="Hủy"
      onCancel={handleCancel}
      width={900}
    >
      <Form
        form={form}
        onValuesChange={handleChangeFilter}
        initialValues={filter}
      >
        <Row gutter={[8, 8]} className="mb-3">
          <Col xxl={6} lg={8} md={12}>
            <Form.Item className="mb-2" name="name">
              <Input placeholder="Từ khóa" allowClear />
            </Form.Item>
          </Col>
          <Col xxl={6} lg={8} md={12}>
            <Form.Item className="mb-2" name="created_from">
              <DatePicker
                format={"DD/MM/YYYY"}
                className="full-width"
                disabledDate={value =>
                  checkDisableDate(value, "created_to", form, "from", true)
                }
                placeholder="Ngày tạo từ"
                allowClear
              />
            </Form.Item>
          </Col>
          <Col xxl={6} lg={8} md={12}>
            <Form.Item className="mb-2" name="created_to">
              <DatePicker
                format={"DD/MM/YYYY"}
                className="full-width"
                disabledDate={value =>
                  checkDisableDate(value, "created_from", form, "to", true)
                }
                placeholder="Ngày tạo đến"
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {isLoading ? (
        <Col
          style={{ textAlign: "center", fontSize: "20px", color: "#6a6a6a" }}
          span={24}
        >
          <Spin />
        </Col>
      ) : (
        <Row gutter={[16, 16]}>
          {data?.data?.map(e => {
            return (
              <Col key={e?.id} xl={8} md={8}>
                <Card
                  className="card-library"
                  style={{
                    width: 250,
                    display: "flex",
                    flexDirection: "column",
                    cursor: "pointer",
                  }}
                  onClick={() => handleSelect(e)}
                >
                  {renderType(type, renderReadFile(e?.file_info?.aws_name))}
                  <Typography.Text
                    className="mt-2"
                    ellipsis={{ tooltip: e?.file_info?.name }}
                  >
                    {e?.file_info?.name}
                  </Typography.Text>
                </Card>
              </Col>
            );
          })}

          {data?.data?.length === 0 && !isLoading && (
            <Col
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "#6a6a6a",
              }}
              span={24}
            >{`Không có dữ liệu`}</Col>
          )}
        </Row>
      )}
    </Modal>
  );
}
