import axiosClient from "apis/axiosClient";
import { METHOD } from "_constants";

const libraryAPI = {
  getAll(params) {
    const url = "/media-files-libraries";
    return axiosClient(METHOD.GET).get(url, { params: { ...params } });
  },
  getById(id) {
    const url = "/media-files-libraries/" + id;
    return axiosClient(METHOD.GET).get(url);
  },
  updateById(id, data) {
    const url = "/media-files-libraries/" + id;
    return axiosClient(METHOD.PPD).put(url, data);
  },
  create(data) {
    const url = "/media-files-libraries";
    return axiosClient(METHOD.PPD).post(url, data);
  },
  export(params) {
    const url = "/media-files-libraries";
    return axiosClient(METHOD.GET).get(url, {
      params: { ...params, download: true },
      responseType: "blob",
    });
  },
};

export default libraryAPI;
