import Icon from "@ant-design/icons";
import { LIST_PERMISSION } from "_constants/Permission/permission";
import { notify } from "layouts/Icon";
import {
  NotificationCreate,
  NotificationDetail,
  NotificationList,
} from "./lazy-import";

const NOTIFICATION_MANAGEMENT = [
  {
    key: "/notifications",
    title: "Quản lý thông báo",
    label: "Quản lý thông báo",
    icon: <Icon component={notify} />,
    component: props => <NotificationList {...props} />,
    action_key: LIST_PERMISSION.NOTIFICATION_VIEW,
    display: 1,
  },
  {
    key: "/notifications/:id",
    title: "Chi tiết thông báo",
    label: "Chi tiết thông báo",
    component: props => <NotificationDetail {...props} />,
    action_key: LIST_PERMISSION.NOTIFICATION_VIEW,
  },
  {
    key: "/notifications/add",
    title: "Thêm thông báo",
    label: "Thêm thông báo",
    component: props => <NotificationCreate {...props} />,
    action_key: LIST_PERMISSION.NOTIFICATION_ADD,
  },
];
export default NOTIFICATION_MANAGEMENT;
