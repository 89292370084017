import Icon from "@ant-design/icons";
import { LIST_PERMISSION } from "_constants/Permission/permission";
import { open } from "layouts/Icon";
import {
  BartenderGuidePostCreate,
  BartenderGuidePostDetail,
  BartenderGuidePostList,
  BookshelfIsTheFoundationCreate,
  BookshelfIsTheFoundationDetail,
  BookshelfIsTheFoundationList,
  LifeChangingMovieCabinetCreate,
  LifeChangingMovieCabinetDetail,
  LifeChangingMovieCabinetList,
  MeditationMusicPostCreate,
  MeditationMusicPostDetail,
  MeditationMusicPostList,
  OtherDocumentPostCreate,
  OtherDocumentPostDetail,
  OtherDocumentPostList,
  BookCreate,
  BookDetail,
  BookList,
} from "./lazy-import";

// eslint-disable-next-line react/display-name
const RenderComponent = Component => props => <Component {...props} />;

const MEDITATION_OPEN = [
  {
    title: "Khai mở Thân - Tâm - Trí ",
    label: "Khai mở Thân - Tâm - Trí ",
    icon: <Icon component={open} />,
    display: 1,
    children: [
      {
        key: "/bookshelf-is-the-foundation",
        title: "Sách",
        label: "Sách",
        component: RenderComponent(BookshelfIsTheFoundationList),
        display: 1,
        action_key: LIST_PERMISSION.OPEN_BOOK_VIEW,
      },
      {
        key: "/bookshelf-is-the-foundation/add",
        title: "Thêm bài viết Sách",
        label: "Thêm bài viết Sách",
        component: RenderComponent(BookshelfIsTheFoundationCreate),
        action_key: LIST_PERMISSION.OPEN_BOOK_ADD,
      },
      {
        key: "/bookshelf-is-the-foundation/:id",
        title: "Chi tiết bài viết Sách",
        label: "Chi tiết bài viết Sách",
        component: RenderComponent(BookshelfIsTheFoundationDetail),
        action_key: LIST_PERMISSION.OPEN_BOOK_VIEW,
      },
      {
        key: "/life-changing-movie-cabinet",
        title: "Tủ phim nền tảng",
        label: "Tủ phim nền tảng",
        component: RenderComponent(LifeChangingMovieCabinetList),
        display: 1,
        action_key: LIST_PERMISSION.BASIC_FILM_VIEW,
      },
      {
        key: "/life-changing-movie-cabinet/add",
        title: "Thêm bài viết Tủ phim nền tảng",
        label: "Thêm bài viết Tủ phim nền tảng",
        component: RenderComponent(LifeChangingMovieCabinetCreate),
        action_key: LIST_PERMISSION.BASIC_FILM_ADD,
      },
      {
        key: "/life-changing-movie-cabinet/:id",
        title: "Chi tiết bài viết Tủ phim nền tảng",
        label: "Chi tiết bài viết Tủ phim nền tảng",
        component: RenderComponent(LifeChangingMovieCabinetDetail),
        action_key: LIST_PERMISSION.BASIC_FILM_VIEW,
      },
      {
        key: "/bartender-guide-post",
        title: "Hướng dẫn pha chế",
        label: "Hướng dẫn pha chế",
        component: RenderComponent(BartenderGuidePostList),
        display: 1,
        action_key: LIST_PERMISSION.BARTENDING_POST_VIEW,
      },
      {
        key: "/bartender-guide-post/add",
        title: "Thêm bài viết Hướng dẫn pha chế",
        label: "Thêm bài viết Hướng dẫn pha chế",
        component: RenderComponent(BartenderGuidePostCreate),
        action_key: LIST_PERMISSION.BARTENDING_POST_ADD,
      },
      {
        key: "/bartender-guide-post/:id",
        title: "Chi tiết bài viết Hướng dẫn pha chế",
        label: "Chi tiết bài viết Hướng dẫn pha chế",
        component: RenderComponent(BartenderGuidePostDetail),
        action_key: LIST_PERMISSION.BARTENDING_POST_VIEW,
      },
      {
        key: "/meditation-music-post",
        title: "Nhạc thiền",
        label: "Nhạc thiền",
        component: RenderComponent(MeditationMusicPostList),
        display: 1,
        action_key: LIST_PERMISSION.OPEN_MUSIC_POST_VIEW,
      },
      {
        key: "/meditation-music-post/add",
        title: "Thêm bài viết Nhạc thiền",
        label: "Thêm bài viết Nhạc thiền",
        component: RenderComponent(MeditationMusicPostCreate),
        action_key: LIST_PERMISSION.OPEN_MUSIC_POST_ADD,
      },
      {
        key: "/meditation-music-post/:id",
        title: "Chi tiết bài viết Nhạc thiền",
        label: "Chi tiết bài viết Nhạc thiền",
        component: RenderComponent(MeditationMusicPostDetail),
        action_key: LIST_PERMISSION.OPEN_MUSIC_POST_VIEW,
      },
      {
        key: "/other-document-post",
        title: "Tài liệu khác",
        label: "Tài liệu khác",
        component: RenderComponent(OtherDocumentPostList),
        display: 1,
        action_key: LIST_PERMISSION.OPEN_OTHER_FILE_POST_VIEW,
      },
      {
        key: "/other-document-post/add",
        title: "Thêm bài viết Tài liệu khác",
        label: "Thêm bài viết Tài liệu khác",
        component: RenderComponent(OtherDocumentPostCreate),
        action_key: LIST_PERMISSION.OPEN_OTHER_FILE_POST_ADD,
      },
      {
        key: "/other-document-post/:id",
        title: "Chi tiết bài viết Tài liệu khác",
        label: "Chi tiết bài viết Tài liệu khác",
        component: RenderComponent(OtherDocumentPostDetail),
        action_key: LIST_PERMISSION.OPEN_OTHER_FILE_POST_VIEW,
      },
      {
        key: "/meditation-coffee-discovery-book",
        title: "5 đầu sách đổi đời",
        label: "5 đầu sách đổi đời",
        component: RenderComponent(BookList),
        display: 1,
        action_key: LIST_PERMISSION.CHANGE_LIFE_BOOK_VIEW,
      },
      {
        key: "/meditation-coffee-discovery-book/:id",
        title: "Chi tiết sách",
        label: "Chi tiết sách",
        component: RenderComponent(BookDetail),
        action_key: LIST_PERMISSION.CHANGE_LIFE_BOOK_VIEW,
      },
      {
        key: "/meditation-coffee-discovery-book/add",
        title: "Thêm sách",
        label: "Thêm sách",
        component: RenderComponent(BookCreate),
        action_key: LIST_PERMISSION.CHANGE_LIFE_BOOK_ADD,
      },
    ],
  },
];
export default MEDITATION_OPEN;
