import Icon from "@ant-design/icons";

import { LIST_PERMISSION } from "_constants/Permission/permission";
import { meditation_coffee } from "layouts/Icon";
import {
  MeditationCoffeePostCreate,
  MeditationCoffeePostDetail,
  MeditationCoffeePostList,
} from "./lazy-import";

const MEDITATION_POST = [
  {
    key: "/meditation-coffee-post",
    title: "Thiền cà phê",
    label: "Thiền cà phê",
    icon: <Icon component={meditation_coffee} />,
    component: props => <MeditationCoffeePostList {...props} />,
    action_key: LIST_PERMISSION.MEDITATION_POST_VIEW,
    display: 1,
  },
  {
    key: "/meditation-coffee-post/:id",
    title: "Chi tiết bài viết",
    label: "Chi tiết bài viết",
    component: props => <MeditationCoffeePostDetail {...props} />,
    action_key: LIST_PERMISSION.MEDITATION_POST_VIEW,
  },
  {
    key: "/meditation-coffee-post/add",
    title: "Thêm bài viết",
    label: "Thêm bài viết",
    component: props => <MeditationCoffeePostCreate {...props} />,
    action_key: LIST_PERMISSION.MEDITATION_POST_ADD,
  },
];
export default MEDITATION_POST;
