import moment from "moment";

export const transformDateToString = date => {
  return moment(date).format("YYYY-MM-DD");
};

export const transformStringToDate = string => {
  return moment(string);
};

export const formatDate = string => {
  if (moment(string).isValid()) {
    return moment(string).format("DD/MM/YYYY");
  }

  return "Chưa xác định";
};

export const formatDateDetail = string => {
  if (string && moment(string).isValid()) {
    return moment(string).format("DD/MM/YYYY HH:mm");
  }

  return "Chưa xác định";
};

export const formatDateSuperDetail = string => {
  if (moment(string).isValid()) {
    return moment(string).format("DD/MM/YYYY HH:mm:ss");
  }

  return "Chưa xác định";
};
