const typeCategoryPost = {
  NC_SUB_BAI_THIEN_CA_PHE: {
    parent_code: undefined,
    value: "NC_SUB_BAI_THIEN_CA_PHE",
    des: "Bài thiền cà phê",
    url: "meditation-coffee-discovery-post",
  },
  NC_SUB_HUONG_DAN_AN_UONG_THUC_DUONG: {
    parent_code: undefined,
    value: "NC_SUB_HUONG_DAN_AN_UONG_THUC_DUONG",
    des: "Thực hành Lối sống tỉnh thức",
    url: "eat-healthy-post",
  },
  NC_SUB_THIEN: {
    parent_code: "NC_THIEN",
    value: "NC_SUB_THIEN",
    des: "Thiền cà phê",
    url: "meditation-coffee-post",
  },
  NC_SUB_REN_THAN: {
    parent_code: "NC_THANG_HOA",
    value: "NC_SUB_REN_THAN",
    des: "Rèn thân",
    url: "meditation-coffee-body-forging-post",
  },
  NC_THIEN_CA_PHE: {
    parent_code: undefined,
    value: "NC_THIEN_CA_PHE",
    des: "Thiền cà phê",
    url: "meditation-coffee-post",
  },
  NC_SUB_DUONG_TAM: {
    parent_code: undefined,
    value: "NC_SUB_DUONG_TAM",
    des: "Dưỡng tâm",
    url: "meditation-coffee-sublime-post",
  },
  NC_SUB_LUYEN_TRI: {
    parent_code: undefined,
    value: "NC_SUB_LUYEN_TRI",
    des: "Luyện trí",
    url: "mind-training-post",
  },
  NC_SUB_LUYEN_TRI_SACH: {
    parent_code: undefined,
    value: "NC_SUB_LUYEN_TRI_SACH",
    des: "Luyện trí(Tủ sách)",
    url: "mind-training-book",
  },
  NC_KHAI_MO: {
    parent_code: undefined,
    value: "NC_KHAI_MO",
    des: "Khai mở thân tâm trí",
    url: "life-changing-movie-cabinet",
  },
  NC_SUB_TU_PHIM_DOI_DOI: {
    parent_code: "NC_KHAI_MO",
    value: "NC_SUB_TU_PHIM_DOI_DOI",
    des: "Tủ phim đổi đời",
    url: "life-changing-movie-cabinet",
  },
  NC_SUB_HUONG_DAN_PHA_CHE: {
    parent_code: "NC_KHAI_MO",
    value: "NC_SUB_HUONG_DAN_PHA_CHE",
    des: "Hướng dẫn pha cà phê",
    url: "bartender-guide-post",
  },
  NC_SUB_NHAC_THIEN: {
    parent_code: "NC_KHAI_MO",
    value: "NC_SUB_NHAC_THIEN",
    des: "Nhạc Thiền",
    url: "meditation-music-post",
  },
  NC_SUB_TAI_LIEU_KHAC: {
    parent_code: "NC_KHAI_MO",
    value: "NC_SUB_TAI_LIEU_KHAC",
    des: "Tài liệu khác",
    url: "other-document-post",
  },
  NC_SUB_CHUYEN_GIA_THIEN_CA_PHE: {
    parent_code: "NC_CHUA_LANH_THAN_TAM_TRI",
    value: "NC_SUB_CHUYEN_GIA_THIEN_CA_PHE",
    des: "Chuyên gia về thiền cà phê",
    url: "coffee-meditation-post",
  },
  NC_SUB_CHUYEN_GIA_THIEN_DUONG_DAI: {
    parent_code: "NC_CHUA_LANH_THAN_TAM_TRI",
    value: "NC_SUB_CHUYEN_GIA_THIEN_DUONG_DAI",
    des: "Chuyên gia về thiền đương đại",
    url: "contemporary-meditation-post",
  },
  NC_SUB_CHUYEN_GIA_YOGA: {
    parent_code: "NC_CHUA_LANH_THAN_TAM_TRI",
    value: "NC_SUB_CHUYEN_GIA_YOGA",
    des: "Chuyên gia về Yoga",
    url: "yoga-post",
  },
  NC_SUB_CHUYEN_GIA_TRI_LIEU: {
    parent_code: "NC_CHUA_LANH_THAN_TAM_TRI",
    value: "NC_SUB_CHUYEN_GIA_TRI_LIEU",
    des: "Chuyên gia Trị liệu",
    url: "therapist-post",
  },
  NC_SUB_HUONG_DAN_KET_NOI_CHIA_SE: {
    parent_code: "NC_CHUA_LANH_THAN_TAM_TRI",
    value: "NC_SUB_HUONG_DAN_KET_NOI_CHIA_SE",
    des: "Hướng dẫn kết nối, chia sẻ với cộng đồng Tỉnh Thức",
    url: "tuturial-connect-post",
  },
  NC_SUB_THONG_TIN_THONG_BAO: {
    parent_code: "NC_BAN_TIN",
    value: "NC_SUB_THONG_TIN_THONG_BAO",
    des: "Thông tin thông báo",
    url: "news-letter",
  },
  NC_SUB_GIOI_THIEU_SAN_PHAM: {
    parent_code: "NC_BAN_TIN",
    value: "NC_SUB_GIOI_THIEU_SAN_PHAM",
    des: "Giới thiệu sản phẩm mới",
    url: "new-product",
  },
  NC_SUB_CHIA_SE: {
    parent_code: "NC_SUB_CHIA_SE",
    value: "NC_SUB_CHIA_SE",
    des: "Chia sẻ kinh nghiệm từ các thành viên",
    url: "sharing-experiances",
  },
};

const listTypeCategoryPost = Object.values(typeCategoryPost);

const urlPost = {
  NC_SUB_CHUYEN_GIA_THIEN_CA_PHE: {
    url: "coffee-meditation-post",
  },
  NC_SUB_CHUYEN_GIA_THIEN_DUONG_DAI: {
    url: "contemporary-meditation-post",
  },
  NC_SUB_CHUYEN_GIA_YOGA: {
    url: "yoga-post",
  },
  NC_SUB_CHUYEN_GIA_TRI_LIEU: {
    url: "therapist-post",
  },
};

export { typeCategoryPost, listTypeCategoryPost, urlPost };
