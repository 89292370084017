import Icon from "@ant-design/icons";
import { LIST_PERMISSION } from "_constants/Permission/permission";
import { library } from "layouts/Icon";
import {
  LibraryAudioList,
  LibraryBookList,
  LibraryCreate,
  LibraryDetail,
  LibraryOtherList,
  LibraryVideoList,
} from "./lazy-import";

const LIBRARY_MANAGEMENT = [
  {
    title: "Thư viện",
    label: "Thư viện",
    icon: <Icon component={library} />,
    display: 1,
    children: [
      // {
      //   key: "/library-images",
      //   title: "Ảnh",
      //   title: "Ảnh",
      //   component: props => <LibraryImagesList {...props} />,
      //   display: 1,
      //   action_key: LIST_PERMISSION. ,
      // },
      {
        key: "/library-video",
        title: "Video",
        label: "Video",
        component: props => <LibraryVideoList {...props} />,
        display: 1,
        action_key: LIST_PERMISSION.LIBRARY_VIDEO_VIEW,
      },
      {
        key: "/library/add",
        title: "Thêm file",
        label: "Thêm file",
        component: props => <LibraryCreate {...props} />,
        action_key: LIST_PERMISSION.LIBRARY_VIDEO_ADD,
      },
      {
        key: "/library/:id",
        title: "Chi tiết file",
        label: "Chi tiết file",
        component: props => <LibraryDetail {...props} />,
        action_key: LIST_PERMISSION.LIBRARY_VIDEO_VIEW,
      },
      {
        key: "/library-audio",
        title: "Audio",
        label: "Audio",
        component: props => <LibraryAudioList {...props} />,
        display: 1,
        action_key: LIST_PERMISSION.LIBRARY_AUDIO_VIEW,
      },
      {
        key: "/library/add",
        title: "Thêm file",
        label: "Thêm file",
        component: props => <LibraryCreate {...props} />,
        action_key: LIST_PERMISSION.LIBRARY_AUDIO_ADD,
      },
      {
        key: "/library/:id",
        title: "Chi tiết file",
        label: "Chi tiết file",
        component: props => <LibraryDetail {...props} />,
        action_key: LIST_PERMISSION.LIBRARY_AUDIO_VIEW,
      },
      {
        key: "/library-book",
        title: "Sách",
        label: "Sách",
        component: props => <LibraryBookList {...props} />,
        display: 1,
        action_key: LIST_PERMISSION.LIBRARY_BOOK_VIEW,
      },
      {
        key: "/library/add",
        title: "Thêm file",
        label: "Thêm file",
        component: props => <LibraryCreate {...props} />,
        action_key: LIST_PERMISSION.LIBRARY_BOOK_ADD,
      },
      {
        key: "/library/:id",
        title: "Chi tiết file",
        label: "Chi tiết file",
        component: props => <LibraryDetail {...props} />,
        action_key: LIST_PERMISSION.LIBRARY_BOOK_VIEW,
      },
      {
        key: "/library-other",
        title: "Tài liệu khác",
        label: "Tài liệu khác",
        component: props => <LibraryOtherList {...props} />,
        display: 1,
        action_key: LIST_PERMISSION.LIBRARY_OTHER_VIEW,
      },
      {
        key: "/library/add",
        title: "Thêm file",
        label: "Thêm file",
        component: props => <LibraryCreate {...props} />,
        action_key: LIST_PERMISSION.LIBRARY_OTHER_ADD,
      },
      {
        key: "/library/:id",
        title: "Chi tiết file",
        label: "Chi tiết file",
        component: props => <LibraryDetail {...props} />,
        action_key: LIST_PERMISSION.LIBRARY_OTHER_VIEW,
      },
    ],
  },
];
export default LIBRARY_MANAGEMENT;
