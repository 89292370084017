import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAPI from "apis/Login/AuthAPI";
import { storage } from "_constants";
import _ from "lodash";

export const loginAsyncThunk = createAsyncThunk("auth/login", async payload => {
  const data = await AuthAPI.login(payload);

  const infoUser = {
    name: data?.name,
    type_id: data?.type_id,
    id: data?.id,
    email: data?.email,
  };

  // save data to local storage
  localStorage.setItem(storage.ACCESS_TOKEN, data.token);
  localStorage.setItem(storage.USER, JSON.stringify(infoUser));
  localStorage.setItem(
    storage.PERMISSION,
    JSON.stringify(_.keyBy(data.list_action, "action_key"))
  );

  return data;
});

export const logoutAsyncThunk = createAsyncThunk(
  "auth/logout",
  async payload => {
    const data = await AuthAPI.logout(payload);

    // clear local storage
    localStorage.removeItem(storage.ACCESS_TOKEN);
    localStorage.removeItem(storage.PERMISSION);
    localStorage.removeItem(storage.USER);

    return data;
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: JSON.parse(localStorage.getItem(storage.USER)) || {},
    token: localStorage.getItem(storage.ACCESS_TOKEN) || undefined,
  },
  reducers: {},
  extraReducers: {
    [loginAsyncThunk.fulfilled]: (state, action) => {
      state.user = action.payload;
    },

    [logoutAsyncThunk.fulfilled]: state => {
      state.user = null;
    },
  },
});
const { reducer } = authSlice;

export default reducer;
