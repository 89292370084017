import Icon from "@ant-design/icons";
import { LIST_PERMISSION } from "_constants/Permission/permission";
import { typeMeditation } from "_constants/personalAwakening";
import { awakening_jorney } from "layouts/Icon";
import {
  MeditationLevelDetail,
  MeditationLevelList,
  MeditationMusicCreate,
  MeditationMusicDetail,
  MeditationMusicList,
  MeditationVideoCreate,
  MeditationVideoDetail,
  MeditationVideoList,
} from "./lazy-import";

const MEDITATION_AWAKENING = [
  {
    title: "Hành trình tỉnh thức cá nhân",
    label: "Hành trình tỉnh thức cá nhân",
    icon: <Icon component={awakening_jorney} />,
    display: 1,
    children: [
      {
        key: "/meditation-level",
        title: "Cấp độ Thiền",
        label: "Cấp độ Thiền",
        display: 1,
        action_key: LIST_PERMISSION.TRIP_LEVEL_VIEW,
        component: props => <MeditationLevelList {...props} />,
      },
      {
        key: "/meditation-level/:id",
        title: "Chi tiết Cấp độ Thiền",
        label: "Chi tiết Cấp độ Thiền",
        component: props => <MeditationLevelDetail {...props} />,
        action_key: LIST_PERMISSION.TRIP_LEVEL_VIEW,
      },
      {
        key: "/meditation-music",
        title: "Nhạc Thiền",
        label: "Nhạc Thiền",
        display: 1,
        action_key: LIST_PERMISSION.TRIP_MUSIC_VIEW,
        component: props => (
          <MeditationMusicList
            {...props}
            permission={{
              add: LIST_PERMISSION.TRIP_MUSIC_ADD,
              export: LIST_PERMISSION.TRIP_MUSIC_EXPORT,
            }}
            type={typeMeditation.music.id}
          />
        ),
      },
      {
        key: "/meditation-music/:id",
        title: "Chi tiết Nhạc Thiền",
        label: "Chi tiết Nhạc Thiền",
        component: props => (
          <MeditationMusicDetail
            {...props}
            permission={{
              update: LIST_PERMISSION.TRIP_MUSIC_UPDATE,
            }}
            type={typeMeditation.music.id}
          />
        ),
        action_key: LIST_PERMISSION.TRIP_MUSIC_VIEW,
      },
      {
        key: "/meditation-music/add",
        title: "Thêm Nhạc Thiền",
        label: "Thêm Nhạc Thiền",
        component: props => (
          <MeditationMusicCreate {...props} type={typeMeditation.music.id} />
        ),
        action_key: LIST_PERMISSION.TRIP_MUSIC_ADD,
      },
      {
        key: "/meditation-podcast",
        title: "Sách nói",
        label: "Sách nói",
        display: 1,
        action_key: LIST_PERMISSION.TRIP_BOOK_VIEW,
        component: props => (
          <MeditationMusicList
            {...props}
            permission={{
              add: LIST_PERMISSION.TRIP_BOOK_ADD,
              export: LIST_PERMISSION.TRIP_BOOK_EXPORT,
            }}
            type={typeMeditation.podcast.id}
          />
        ),
      },
      {
        key: "/meditation-podcast/:id",
        title: "Chi tiết Sách nói",
        label: "Chi tiết Sách nói",
        component: props => (
          <MeditationMusicDetail
            {...props}
            permission={{
              update: LIST_PERMISSION.TRIP_BOOK_UPDATE,
            }}
            type={typeMeditation.podcast.id}
          />
        ),
        action_key: LIST_PERMISSION.TRIP_BOOK_VIEW,
      },
      {
        key: "/meditation-podcast/add",
        title: "Thêm Sách nói",
        label: "Thêm Sách nói",
        component: props => (
          <MeditationMusicCreate {...props} type={typeMeditation.podcast.id} />
        ),
        action_key: LIST_PERMISSION.TRIP_BOOK_ADD,
      },
      {
        key: "/meditation-video",
        title: "Video Thiền",
        label: "Video Thiền",
        display: 1,
        action_key: LIST_PERMISSION.TRIP_VIDEO_VIEW,
        component: props => <MeditationVideoList {...props} />,
      },
      {
        key: "/meditation-video/:id",
        title: "Chi tiết Video Thiền",
        label: "Chi tiết Video Thiền",
        component: props => <MeditationVideoDetail {...props} />,
        action_key: LIST_PERMISSION.TRIP_VIDEO_VIEW,
      },
      {
        key: "/meditation-video/add",
        title: "Thêm Video Thiền",
        label: "Thêm Video Thiền",
        component: props => <MeditationVideoCreate {...props} />,
        action_key: LIST_PERMISSION.TRIP_VIDEO_ADD,
      },
    ],
  },
];
export default MEDITATION_AWAKENING;
