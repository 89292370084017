export const listPathname = {
  manager_ship_fee: {
    sub: "/management-shipping",
    list: "/management-shipping",
    add: "/management-shipping/add",
    details: "/management-shipping/:id",
  },
  manager_member: {
    sub: "/management-member",
    groups: {
      list: "/management-member/groups-member",
      add: "/management-member/groups-member/add",
      details: "/management-member/groups-member/:id",
    },
    members: {
      list: "/management-member/members",
      detail: "/management-member/members/:id",
    },
  },
  manager_product: {
    sub: "/management-product",
    groups: {
      list: "/management-product/groups-product",
      add: "/management-product/groups-product/add",
      detail: "/management-product/groups-product/:id",
    },
    products: {
      list: "/management-product/products",
      detail: "/management-product/products/:id",
      add: "/management-product/products/add",
    },
    vat: {
      list: "/management-product/vat",
      detail: "/management-product/vat/:id",
    },
  },
  promotion_management: {
    sub: "/management-promotion",
    list: "/management-promotion",
    add: "/management-promotion/add",
    detail: "/management-promotion/:id",
  },
  management_user: {
    sub: "/management-user",
    groups: {
      list: "/management-user/groups-user",
      add: "/management-user/groups-user/add",
      details: "/management-user/groups-user/:id",
    },
    users: {
      list: "/management-user/users",
      detail: "/management-user/users/:id",
      add: "/management-user/users/add",
    },
  },
};
