const statusGender = {
  male: {
    id: 1,
    key: "1",
    name: "Nam",
  },
  female: {
    id: 2,
    key: "2",
    name: "Nữ",
  },
};
const listStatusGender = Object.values(statusGender);
const findStatusGender = (id, field) => {
  const result = listStatusGender.find(e => e.id === id * 1);
  return result?.[field] || result;
};

const typeCreator = {
  member: {
    id: 1,
    name: "Thành viên",
  },
  user: {
    id: 2,
    name: "Admin",
  },
};

const typeFilter = {
  number: "number",
  boolean: "boolean",
  moment: "moment",
  array: "array",
  string: "string",
};

export { listStatusGender, findStatusGender, typeCreator, typeFilter };
