import React, { useState } from "react";
import { Button, message } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
const FileDownload = require("js-file-download");

const ExportButton = ({
  title,
  file_name,
  func,
  className,
  hidden,
  ...buttonProps
}) => {
  const [exporting, setExport] = useState(false);

  const exportFile = async name => {
    setExport(true);
    // return message.info('Tính năng đang xây dựng')
    let fileName = name + "_" + moment().format("YYYYMMDD-HHmm") + ".xlsx";
    try {
      let res = await func();
      if (res) {
        FileDownload(res, fileName.replace(/\s/gm, "_"));
      } else {
        message.error("Xuất dữ liệu lỗi! Kiểm tra kết nối hệ thống");
      }
      setExport(false);
    } catch (error) {
      message.error("Xuất dữ liệu lỗi! Kiểm tra kết nối hệ thống");
      setExport(false);
    }
  };

  return (
    <Button
      hidden={hidden}
      className={`${className}`}
      loading={exporting}
      onClick={() => exportFile(file_name)}
      type="primary"
      ghost
      icon={<DownloadOutlined />}
      {...buttonProps}
    >
      {title}
    </Button>
  );
};
export default ExportButton;
