import DASHBOARD from "./dashboard";
import MEMBER_MANAGEMENT from "./member-management";
import PRODUCT_MANAGEMENT from "./product-management";
import USER_MANAGEMENT from "./user-management";
import VOUCHER from "./voucher-management";
import ORDER from "./order-management";
import SHIPPING_FEE from "./shipping-fee";
import PROMOTION from "./promotion-management";
import QUESTION from "./question-management";
import GUEST from "./guest-management";
import MEDITATION_POST from "./meditation-post";
import MEDITATION_DISCOVERY from "./meditation-discovery";
import MEDITATION_SUBLIMATE from "./meditation-sublimate";
import MEDITATION_OPEN from "./meditation-open";
import MEDITATION_HEAL from "./meditation-heal";
import MEDITATION_NEWS from "./meditation-news";
import MEDITATION_AWAKENING from "./meditation-awakening";
import AWAKENING_COMMUNITY from "./awakening-community";
import GAME_MANAGEMENT from "./game-management";
import LIBRARY_MANAGEMENT from "./library-management";
import NOTIFICATION_MANAGEMENT from "./notification-management";
import VERSION_MANAGEMENT from "./version-management";
import STICKER_MANAGEMENT from "./sticker-management";

const NAVIGATE_LIST = {
  DASHBOARD,
  MEMBER_MANAGEMENT,
  PRODUCT_MANAGEMENT,
  USER_MANAGEMENT,
  VOUCHER,
  ORDER,
  SHIPPING_FEE,
  PROMOTION,
  QUESTION,
  GUEST,
  MEDITATION_POST,
  MEDITATION_DISCOVERY,
  MEDITATION_SUBLIMATE,
  MEDITATION_OPEN,
  MEDITATION_HEAL,
  MEDITATION_NEWS,
  MEDITATION_AWAKENING,
  AWAKENING_COMMUNITY,
  GAME_MANAGEMENT,
  LIBRARY_MANAGEMENT,
  NOTIFICATION_MANAGEMENT,
  VERSION_MANAGEMENT,
  STICKER_MANAGEMENT,
};

export default NAVIGATE_LIST;
