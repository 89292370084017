import Icon from "@ant-design/icons";

import { LIST_PERMISSION } from "_constants/Permission/permission";
import { sublimation } from "layouts/Icon";
import {
  BodyForgingCategoryCreate,
  BodyForgingCategoryDetail,
  BodyForgingCategoryList,
  BodyForgingPostCreate,
  BodyForgingPostDetail,
  BodyForgingPostList,
  MindTrainingBookCreate,
  MindTrainingBookDetail,
  MindTrainingBookList,
  MindTrainingPostCreate,
  MindTrainingPostDetail,
  MindTrainingPostList,
  NurturingTheMindCreate,
  NurturingTheMindDetail,
  NurturingTheMindList,
} from "./lazy-import";

const MEDITATION_SUBLIMATE = [
  {
    key: "/meditation-coffee-body-forging-category-sub",
    title: "Thăng hoa cùng Thiền cà phê",
    label: "Thăng hoa cùng Thiền cà phê",
    icon: <Icon component={sublimation} />,
    display: 1,
    children: [
      {
        title: "Rèn thân",
        label: "Rèn thân",
        display: 1,
        children: [
          {
            key: "/meditation-coffee-body-forging-category",
            title: "Danh mục",
            label: "Danh mục",
            component: props => <BodyForgingCategoryList {...props} />,
            display: 1,
            action_key: LIST_PERMISSION.BUILD_BODY_CATEGORY_VIEW,
          },
          {
            key: "/meditation-coffee-body-forging-category/add",
            title: "Thêm danh mục",
            label: "Thêm danh mục",
            component: props => <BodyForgingCategoryCreate {...props} />,
            action_key: LIST_PERMISSION.BUILD_BODY_CATEGORY_ADD,
          },
          {
            key: "/meditation-coffee-body-forging-category/:id",
            title: "Chi tiết danh mục",
            label: "Chi tiết danh mục",
            component: props => <BodyForgingCategoryDetail {...props} />,
            action_key: LIST_PERMISSION.BUILD_BODY_CATEGORY_VIEW,
          },
          {
            key: "/meditation-coffee-body-forging-post",
            title: "Bài viết",
            label: "Bài viết",
            component: props => <BodyForgingPostList {...props} />,
            display: 1,
            action_key: LIST_PERMISSION.BUILD_BODY_POST_VIEW,
          },
          {
            key: "/meditation-coffee-body-forging-post/:id",
            title: "Chi tiết bài viết",
            label: "Chi tiết bài viết",
            component: props => <BodyForgingPostDetail {...props} />,
            action_key: LIST_PERMISSION.BUILD_BODY_POST_VIEW,
          },
          {
            key: "/meditation-coffee-body-forging-post/add",
            title: "Thêm bài viết",
            label: "Thêm bài viết",
            component: props => <BodyForgingPostCreate {...props} />,
            action_key: LIST_PERMISSION.BUILD_BODY_CATEGORY_ADD,
          },
        ],
      },
      {
        key: "/meditation-coffee-sublime-post",
        title: "Dưỡng tâm",
        label: "Dưỡng tâm",
        component: props => <NurturingTheMindList {...props} />,
        display: 1,
        action_key: LIST_PERMISSION.BUILD_SOUL_POST_VIEW,
      },
      {
        key: "/meditation-coffee-sublime-post/add",
        title: "Thêm bài viết dưỡng tâm",
        label: "Thêm bài viết dưỡng tâm",
        component: props => <NurturingTheMindCreate {...props} />,
        action_key: LIST_PERMISSION.BUILD_SOUL_POST_ADD,
      },
      {
        key: "/meditation-coffee-sublime-post/:id",
        title: "Chi tiết bài viết dưỡng tâm",
        label: "Chi tiết bài viết dưỡng tâm",
        component: props => <NurturingTheMindDetail {...props} />,
        action_key: LIST_PERMISSION.BUILD_SOUL_POST_VIEW,
      },
      {
        key: "/mind-training-post",
        title: "Luyện trí",
        label: "Luyện trí",
        component: props => <MindTrainingPostList {...props} />,
        display: 1,
        action_key: LIST_PERMISSION.MENTAL_TRAINING_POST_VIEW,
      },
      {
        key: "/mind-training-post/add",
        title: "Thêm Bài viết luyện trí",
        label: "Thêm Bài viết luyện trí",
        component: props => <MindTrainingPostCreate {...props} />,
        action_key: LIST_PERMISSION.MENTAL_TRAINING_POST_CREATE,
      },
      {
        key: "/mind-training-post/:id",
        title: "Chi tiết Bài viết luyện trí",
        label: "Chi tiết Bài viết luyện trí",
        component: props => <MindTrainingPostDetail {...props} />,
        action_key: LIST_PERMISSION.MENTAL_TRAINING_POST_VIEW,
      },
      {
        key: "/mind-training-book",
        title: "Tủ sách nền tảng",
        label: "Tủ sách nền tảng",
        component: props => <MindTrainingBookList {...props} />,
        display: 0,
        action_key: LIST_PERMISSION.BASIC_BOOK_VIEW,
      },
      {
        key: "/mind-training-book/add",
        title: "Thêm Tủ sách nền tảng",
        label: "Thêm Tủ sách nền tảng",
        component: props => <MindTrainingBookCreate {...props} />,
        action_key: LIST_PERMISSION.BASIC_BOOK_ADD,
      },
      {
        key: "/mind-training-book/:id",
        title: "Chi tiết Tủ sách nền tảng",
        label: "Chi tiết Tủ sách nền tảng",
        component: props => <MindTrainingBookDetail {...props} />,
        action_key: LIST_PERMISSION.BASIC_BOOK_VIEW,
      },
    ],
  },
];
export default MEDITATION_SUBLIMATE;
